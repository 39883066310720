.case-single {
	@include media('tablet-portrait-down') {
		padding-bottom: 50px;
	}
	margin-top: 40px;
	// padding-bottom: 100px;
	position: relative;
	.wrapper {
		min-height: 550px;
		height: 100%;
	}

	.graphic {
		@include media('tablet-portrait-down') {
			min-width: 700px;
			bottom: -150px;
		}
		position: absolute;
		bottom: -210px;
		right: -15px;
		min-width: calc(100% + 20px);
		left: 0;
		z-index: -2;
	}
}
.case-single__image {
	@include media('tablet-portrait-down') {
		width: 100%;
		order: 2;
		min-height: auto;
		border-radius: 0 0 16px 16px;
	}
	min-height: 550px;
	height: auto;
	width: 50%;
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: flex-end;
	border-radius: 16px 0 0 16px;
}

.case-single__info {
	@include media('tablet-landscape-down') {
		padding-left: 30px;
		padding-right: 30px;
	}
	@include media('tablet-portrait-down') {
		width: 100%;
		order: 1;
		border-radius: 16px 16px 0 0;
		padding-left: 20px;
		padding-right: 20px;
		padding-top: 30px;
	}
	width: 50%;
	background-color: $purple-100;
	color: $ghost-white-100;
	padding-left: 100px;
	padding-right: 80px;
	padding-top: 55px;
	padding-bottom: 55px;
	border-radius: 0 16px 168px 0;
	display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.case-single__background {
	@include media('tablet-portrait-down') {
		min-height: auto;
	}
	min-height: 550px;
	width: 100%;
	height: 100%;
	border-radius: 16px 0 0 16px;
	z-index: -1;

	img {
		@include media('desktop') {
			position: absolute;
			z-index: -1;
		}
		@include media('tablet-portrait-down') {
			border-radius: 0 0 16px 16px;
			min-height: auto;
		}
		min-height: 550px;
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 16px 0 0 16px;
		object-position: right;
	}

	&::before {
		@include media('tablet-portrait-down') {
			border-radius: 0 0 16px 16px;
		}
		content: '';
		width: 100%;
		height: 200px;
		position: absolute;
		top: 0;
		left: 0;
		background: linear-gradient(180deg, #0A0141 0%, rgba(18, 1, 88, 0) 100%);
		opacity: 0.3;
		pointer-events: none;
		border-radius: 16px 0 0 0;
	}

	&::after {
		@include media('tablet-portrait-down') {
			border-radius: 0 0 16px 16px;
		}
		content: '';
		width: 100%;
		height: 200px;
		position: absolute;
		bottom: 0;
		left: 0;
		background: linear-gradient(180deg, #0A0141 0%, rgba(18, 1, 88, 0) 100%);
		opacity: 0.8;
		transform: rotate(-180deg);
		pointer-events: none;
		border-radius: 0 16px 0 0;
	}
}

.case-single__logo {
	@include media('tablet-portrait-down') {
		padding: 20px;
	}
	z-index: 10;
	padding: 45px;
	position: absolute;
	img {
		@include media('tablet-portrait-down') {
			max-height: 70px;
		}
		max-height: 100px;
		max-width: 300px;
		width: 100%;
		object-fit: contain;
		object-position: left;
	}
}

.case-single__name {
	font-weight: 400;
	font-size: 18px;
	line-height: 160%;
	padding-bottom: 10px;
	margin-bottom: 10px;
	border-bottom: 1px solid $white-100;
}

.case-singe__title {
	color: $ghost-white-100;
	font-size: 36px;
	line-height: 43px;
	margin-bottom: 40px;
}

.case-single__review {
	.case-single {
		padding: 40px;
		background-color: $ghost-white-100;
		box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
		border-radius: 10px;
		border: 1px solid rgba(29, 1, 88, 0.04);
		display: flex;
		flex-wrap: wrap;
		width: 600px;
	}
	
	.case-single__user {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 25px;
	}
	
	.case-single__user-person {
		width: 80px;
		height: 80px;
		margin-right: 20px;
	
		img {
			width: 80px;
			height: 80px;
			border-radius: 100px;
			border: 3px solid $blue-100;
		}
	}
	
	.case-single__user-details {
		width: calc(100% - 100px);
		display: flex;
		flex-wrap: wrap;
		align-content: center;
	}
	
	.case-single__user-details-name {
		width: 100%;
		color: $white-100;
		font-size: 800;
		line-height: 27px;
		font-size: 20px;
		margin-bottom: 4px;
	}
	
	.case-single__user-details-function {
		width: 100%;
		color: $blue-100;
		font-weight: 500;
		font-size: 16px;
		line-height: 22px;
	}
	
	.case-single__quote {
		margin-bottom: 34px;
	}
	
	.case-single__review-number {
		color: $white-100;
		font-size: 14px;
		line-height: 26px;
		margin-right: 10px;
		font-weight: 500px;
	}
	
	.case-single__review {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		margin-bottom: 34px;
		align-content: center;
	}
	
	.case-single__review-stars {
		display: flex;
		flex-wrap: wrap;
		align-content: center;
	
		svg {
			margin-right: 4px;
			width: 14px;
			height: 14px;
			fill: #FDB002;
		}
	}
	
	.case-single__link {
		a {
			font-weight: 500;
			font-size: 18px;
			line-height: 120%;
			letter-spacing: -0.02em;
			text-decoration-line: underline;
			font-family: map-get($font-family, 'heading');
			color: $purple-100;
			transition: all 300ms ease-in-out;
	
			&:hover {
				opacity: 0.6;
			}
		}
	}
}

.case-single__back {
	@include media('tablet-portrait-down') {
		margin-top: 40px;
	}
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-top: 100px;
	transition: all 300ms ease-in-out;

	span {
		@include media('tablet-portrait-down') {
			font-size: 13px;
		}
		font-weight: 500;
		font-size: 18px;
		line-height: 120%;
		letter-spacing: -0.02em;
		text-decoration-line: underline;
	}

	span.svg {
		@include media('tablet-portrait-down') {
			width: 25px;
			height: 25px;
		}
		margin-right: 24px;
		width: 50px;
		height: 50px;
		border-radius: 100px;
		background-color: $purple-100;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		filter: drop-shadow(0px 8px 10px rgba(0, 0, 0, 0.1));

		svg {
			@include media('tablet-portrait-down') {
				width: 17px;
				height: 17px;
			}
			fill: $white-100;
			width: 22px;
			height: 22px;
		}
	}

	&:hover {
		opacity: 0.6;
	}
}


.case-single__see-more {
	@include media('tablet-portrait-down') {
		display: none;
	}
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	text-align: center;
	position: relative;
	width: 100%;
	margin-top: 40px;


	span {
		font-weight: 400;
		font-size: 18px;
		line-height: 160%;
		width: 100%;
		margin-bottom: 4px;
	}

	&::after {
		content: '';
		display: block;
		width: 2px;
		height: 70px;
		background-color: $blue-100;		
		border-radius: 5px;
	}

	&::before {
		content: '';
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		display: block;
		width: 2px;
		height: 70px;
		background-color: $purple-100;		
		border-radius: 5px;
		animation: scroll-jump 3s ease-in-out infinite;
	}
}