/**
 * Color Map (https://convertacolor.com/).
 */

$colors: (
	'black': (
		'100': hsla(0, 0%, 0%, 1), // #000000, 100%
		'90': hsla(0, 0%, 0%, 0.9), // #000000, 90%
		'80': hsla(0, 0%, 0%, 0.8), // #000000, 80%
		'70': hsla(0, 0%, 0%, 0.7), // #000000, 70%
		'60': hsla(0, 0%, 0%, 0.6), // #000000, 60%
		'50': hsla(0, 0%, 0%, 0.5), // #000000, 50%
		'40': hsla(0, 0%, 0%, 0.4), // #000000, 40%
		'30': hsla(0, 0%, 0%, 0.3), // #000000, 30%
		'20': hsla(0, 0%, 0%, 0.2), // #000000, 20%
		'10': hsla(0, 0%, 0%, 0.1), // #000000, 10%
	),
	'white': (
		'100': hsla(217, 0%, 100%, 1), // #FFFFFF, 100%
		'90': hsla(217, 0%, 100%, 0.9), // #FFFFFF, 90%
		'80': hsla(217, 0%, 100%, 0.8), // #FFFFFF, 80%
		'70': hsla(217, 0%, 100%, 0.7), // #FFFFFF, 70%
		'60': hsla(217, 0%, 100%, 0.6), // #FFFFFF, 60%
		'50': hsla(217, 0%, 100%, 0.5), // #FFFFFF, 50%
		'40': hsla(217, 0%, 100%, 0.4), // #FFFFFF, 40%
		'30': hsla(217, 0%, 100%, 0.3), // #FFFFFF, 30%
		'20': hsla(217, 0%, 100%, 0.2), // #FFFFFF, 20%
		'10': hsla(217, 0%, 100%, 0.1), // #FFFFFF, 10%
	),
	'ghost-white': (
		'100': hsla(230, 50%, 98%, 1), // #F6F7FC, 100%
		'90': hsla(230, 50%, 98%, 0.9), // #F6F7FC, 90%
		'80': hsla(230, 50%, 98%, 0.8), // #F6F7FC, 80%
		'70': hsla(230, 50%, 98%, 0.7), // #F6F7FC, 70%
		'60': hsla(230, 50%, 98%, 0.6), // #F6F7FC, 60%
		'50': hsla(230, 50%, 98%, 0.5), // #F6F7FC, 50%
		'40': hsla(230, 50%, 98%, 0.4), // #F6F7FC, 40%
		'30': hsla(230, 50%, 98%, 0.3), // #F6F7FC, 30%
		'20': hsla(230, 50%, 98%, 0.2), // #F6F7FC, 20%
		'10': hsla(230, 50%, 98%, 0.1), // #F6F7FC, 10%
	),
	'red': (
		'100': hsla(0, 100%, 50%, 1),  // #FF0000, 100%
		'90': hsla(0, 100%, 50%, 0.9), // #FF0000, 90%
		'80': hsla(0, 100%, 50%, 0.8), // #FF0000, 90%
		'70': hsla(0, 100%, 50%, 0.7), // #FF0000, 90%
		'60': hsla(0, 100%, 50%, 0.6), // #FF0000, 90%
		'50': hsla(0, 100%, 50%, 0.5), // #FF0000, 90%
		'40': hsla(0, 100%, 50%, 0.4), // #FF0000, 90%
		'30': hsla(0, 100%, 50%, 0.3), // #FF0000, 90%
		'20': hsla(0, 100%, 50%, 0.2), // #FF0000, 90%
		'10': hsla(0, 100%, 50%, 0.1)  // #FF0000, 10%
	),
	'purple': (
		'100': hsla(252, 98%, 17%, 1),  // #120158, 100%
		'90': hsla(252, 98%, 17%, 0.9), // #120158, 90%
		'80': hsla(252, 98%, 17%, 0.8), // #120158, 80%
		'70': hsla(252, 98%, 17%, 0.7), // #120158, 70%
		'60': hsla(252, 98%, 17%, 0.6), // #120158, 60%
		'50': hsla(252, 98%, 17%, 0.5), // #120158, 50%
		'40': hsla(252, 98%, 17%, 0.4), // #120158, 40%
		'30': hsla(252, 98%, 17%, 0.3), // #120158, 30%
		'20': hsla(252, 98%, 17%, 0.2), // #120158, 20%
		'10': hsla(252, 98%, 17%, 0.1), // #120158, 10%
	),
	'dark-purple': (
		'100': hsla(248, 98%, 13%, 1),  // #0A0141, 100%
		'90': hsla(248, 98%, 13%, 0.9), // #0A0141, 90%
		'80': hsla(248, 98%, 13%, 0.8), // #0A0141, 80%
		'70': hsla(248, 98%, 13%, 0.7), // #0A0141, 70%
		'60': hsla(248, 98%, 13%, 0.6), // #0A0141, 60%
		'50': hsla(248, 98%, 13%, 0.5), // #0A0141, 50%
		'40': hsla(248, 98%, 13%, 0.4), // #0A0141, 40%
		'30': hsla(248, 98%, 13%, 0.3), // #0A0141, 30%
		'20': hsla(248, 98%, 13%, 0.2), // #0A0141, 20%
		'10': hsla(248, 98%, 13%, 0.1), // #0A0141, 10%
	),
	'blue': (
		'100': hsla(198, 98%, 56%, 1),  // #22BBFD, 100%
		'90': hsla(198, 98%, 56%, 0.9), // #22BBFD, 90%
		'80': hsla(198, 98%, 56%, 0.8), // #22BBFD, 80%
		'70': hsla(198, 98%, 56%, 0.7), // #22BBFD, 70%
		'60': hsla(198, 98%, 56%, 0.6), // #22BBFD, 60%
		'50': hsla(198, 98%, 56%, 0.5), // #22BBFD, 50%
		'40': hsla(198, 98%, 56%, 0.4), // #22BBFD, 40%
		'30': hsla(198, 98%, 56%, 0.3), // #22BBFD, 30%
		'20': hsla(198, 98%, 56%, 0.2), // #22BBFD, 20%
		'10': hsla(198, 98%, 56%, 0.1), // #22BBFD, 10%
	),
);


/**
 * Colors Variables.
 */
$black-100: map-get(map-get($colors, 'black'), '100');
$black-90: map-get(map-get($colors, 'black'), '90');
$black-80: map-get(map-get($colors, 'black'), '80');
$black-70: map-get(map-get($colors, 'black'), '70');
$black-60: map-get(map-get($colors, 'black'), '60');
$black-50: map-get(map-get($colors, 'black'), '50');
$black-40: map-get(map-get($colors, 'black'), '40');
$black-30: map-get(map-get($colors, 'black'), '30');
$black-20: map-get(map-get($colors, 'black'), '20');
$black-10: map-get(map-get($colors, 'black'), '10');

$white-100: map-get(map-get($colors, 'white'), '100');
$white-90: map-get(map-get($colors, 'white'), '90');
$white-80: map-get(map-get($colors, 'white'), '80');
$white-70: map-get(map-get($colors, 'white'), '70');
$white-60: map-get(map-get($colors, 'white'), '60');
$white-50: map-get(map-get($colors, 'white'), '50');
$white-40: map-get(map-get($colors, 'white'), '40');
$white-30: map-get(map-get($colors, 'white'), '30');
$white-20: map-get(map-get($colors, 'white'), '20');
$white-10: map-get(map-get($colors, 'white'), '10');

$ghost-white-100: map-get(map-get($colors, 'ghost-white'), '100');
$ghost-white-90: map-get(map-get($colors, 'ghost-white'), '90');
$ghost-white-80: map-get(map-get($colors, 'ghost-white'), '80');
$ghost-white-70: map-get(map-get($colors, 'ghost-white'), '70');
$ghost-white-60: map-get(map-get($colors, 'ghost-white'), '60');
$ghost-white-50: map-get(map-get($colors, 'ghost-white'), '50');
$ghost-white-40: map-get(map-get($colors, 'ghost-white'), '40');
$ghost-white-30: map-get(map-get($colors, 'ghost-white'), '30');
$ghost-white-20: map-get(map-get($colors, 'ghost-white'), '20');
$ghost-white-10: map-get(map-get($colors, 'ghost-white'), '10');

$purple-100: map-get(map-get($colors, 'purple'), '100');
$purple-90: map-get(map-get($colors, 'purple'), '90');
$purple-80: map-get(map-get($colors, 'purple'), '80');
$purple-70: map-get(map-get($colors, 'purple'), '70');
$purple-60: map-get(map-get($colors, 'purple'), '60');
$purple-50: map-get(map-get($colors, 'purple'), '50');
$purple-40: map-get(map-get($colors, 'purple'), '40');
$purple-30: map-get(map-get($colors, 'purple'), '30');
$purple-20: map-get(map-get($colors, 'purple'), '20');
$purple-10: map-get(map-get($colors, 'purple'), '10');

$dark-purple-100: map-get(map-get($colors, 'dark-purple'), '100');
$dark-purple-90: map-get(map-get($colors, 'dark-purple'), '90');
$dark-purple-80: map-get(map-get($colors, 'dark-purple'), '80');
$dark-purple-70: map-get(map-get($colors, 'dark-purple'), '70');
$dark-purple-60: map-get(map-get($colors, 'dark-purple'), '60');
$dark-purple-50: map-get(map-get($colors, 'dark-purple'), '50');
$dark-purple-40: map-get(map-get($colors, 'dark-purple'), '40');
$dark-purple-30: map-get(map-get($colors, 'dark-purple'), '30');
$dark-purple-20: map-get(map-get($colors, 'dark-purple'), '20');
$dark-purple-10: map-get(map-get($colors, 'dark-purple'), '10');

$blue-100: map-get(map-get($colors, 'blue'), '100');
$blue-90: map-get(map-get($colors, 'blue'), '90');
$blue-80: map-get(map-get($colors, 'blue'), '80');
$blue-70: map-get(map-get($colors, 'blue'), '70');
$blue-60: map-get(map-get($colors, 'blue'), '60');
$blue-50: map-get(map-get($colors, 'blue'), '50');
$blue-40: map-get(map-get($colors, 'blue'), '40');
$blue-30: map-get(map-get($colors, 'blue'), '30');
$blue-20: map-get(map-get($colors, 'blue'), '20');
$blue-10: map-get(map-get($colors, 'blue'), '10');

$red-100: map-get(map-get($colors, 'red'), '100');
$red-90: map-get(map-get($colors, 'red'), '90');
$red-80: map-get(map-get($colors, 'red'), '80');
$red-70: map-get(map-get($colors, 'red'), '70');
$red-60: map-get(map-get($colors, 'red'), '60');
$red-50: map-get(map-get($colors, 'red'), '50');
$red-40: map-get(map-get($colors, 'red'), '40');
$red-30: map-get(map-get($colors, 'red'), '30');
$red-20: map-get(map-get($colors, 'red'), '20');
$red-10: map-get(map-get($colors, 'red'), '10');