/**
 * Object fit.
 *
 * 1. Font family polyfill with (https://github.com/bfred-it/object-fit-images).
 * Loop through every value for the `object-fit` property.
 */

@each $value in fill, contain, cover, none, scale-down {

	/**
	 * `object-fit` #{$value} utility class.
	 */

	.#{$value} {
		font-family: 'object-fit: #{$value};'; /* 1 */
		object-fit: #{$value};
	}
}
