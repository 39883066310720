/**
 * Container
 * 
 * Center aligns element with given maximum width, padding, and margins top and bottom.
 * @param {String} $width - Pixel unit value.
 * @param {String} $gap - Pixel unit value.
 * @example @include container(1024px, 20px);
 */

@mixin container($width: $grid-width, $gap: $grid-gap) {
	@if (type-of($width) == number and type-of($gap) == number) {
		@if ($gap != 0) {
			max-width: calc(#{$width} + #{($gap * 2)});
			padding: 0 $gap;
		} @else {
			max-width: $width;
		}
		width: 100%;
		margin: 0 auto;
	} @else {
		@error 'Arguments `$width` and `$gap` should be a number';
	}
}


/**
 * Section
 *
 * Add top and bottom spacing to element using paddings.
 * @param {String} $top - Pixel unit value.
 * @param {String} $bottom - Pixel unit value.
 * @example @include section(100px, 100px);
 */ 

@mixin section($top: 0, $bottom: 0) {
	@if (type-of($top) == number and type-of($bottom) == number) {
		padding: $top 0 $bottom;
	} @else {
		@error 'Arguments `$top` and `bottom` should be a number';
	}
}


/**
 * Font.
 *
 * Adds `rem` unit based font size property, a fallback `px` unit based font size property, and a `em` unit based line height property to any rule.
 * @param {String} $font-size - Pixel unit value.
 * @param {String} $line-height - Pixel unit value.
 * @example @include font-size(16px, 24px);
 */

@mixin font($font-size, $line-height) {
	@if (type-of($font-size) == number and unit($font-size) == 'px') {
		font-size: $font-size;
		font-size: rem($font-size); // sass-lint:disable-line no-duplicate-properties
	} @else {
		@error 'Value for `#{$font-size}` should be a `px` unit';
	}
	@if (type-of($line-height) == number and unit($line-height) == 'px') {
		line-height: (($line-height / $font-size) * 1em);
	} @else {
		@error 'Value for `#{$line-height}` should be a `px` unit';
	}
}


/**
 * Text.
 *
 * Shorthand for the font mixin, runs the font mixin based on a common demeanour existing both in the `$font-size` and the `$line-height` maps.
 * @param {string} $value - String
 * @example @include text('p');
 */ 

@mixin text($value) {
	@if (type-of($value) == string) {
		@include font(map-get($font-size, $value), map-get($line-height, $value));
	} @else {
		@error 'Value should be a string';
	}
}


/**
 * Font Awesome.
 *
 * Add Font Awesome icons as psuedo elements by using their unicode.
 * @param {String} $content - Unicode string.
 * @param {String} $family - Font Awesome font family string.
 * @example @include fontawesome('f100', 'Light');
 */

@mixin fontawesome($content, $family) {
	@if (type-of($family) == string) {
		display: none;
		font-family: 'Font Awesome 5 #{$family}';
		content: unicode($content);
	} @else {
		@error 'Argument `$family` should be a string';
	}
}


/**
 * Media.
 *
 * Media queries according to "The 100% Correct Way To Do CSS Breakpoints" (https://medium.freecodecamp.com/the-100-correct-way-to-do-css-breakpoints-88d6a5ba1862).
 * @param {String} $breakpoint - Breakpoint pixel unit value.
 * @example @include media('phone') {}
 */ 
 
@mixin media($size) {
	@if ($size == 'desktop') {
		@media only screen and(min-width: 1200px) {
			@content;
		}
	} @else if ($size == 'tablet-landscape-up') {
		@media only screen and(min-width: 900px) {
			@content;
		}
	} @else if ($size == 'tablet-landscape') {
		@media only screen and(min-width: 900px) and(max-width: 1199px) {
			@content;
		}
	} @else if ($size == 'tablet-landscape-down') {
		@media only screen and(max-width: 1199px) {
			@content;
		}
	} @else if ($size == 'tablet-portrait-up') {
		@media only screen and(min-width: 600px) {
			@content;
		}
	} @else if ($size == 'tablet-portrait') {
		@media only screen and(min-width: 600px) and(max-width: 899px) {
			@content;
		}
	} @else if ($size == 'tablet-portrait-down') {
		@media only screen and(max-width: 899px) {
			@content;
		}
	} @else if ($size == 'tablet') {
		@media only screen and(min-width: 600px) and(max-width: 1199px) {
			@content;
		}
	} @else if ($size == 'phone') {
		@media only screen and(max-width: 599px) {
			@content;
		}
	} @else {
		@content;
	}
}
