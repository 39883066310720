.pricelist {
	@include media('tablet-portrait-down') {
		padding-top: 60px;
	}
	padding-bottom: 40px;
	padding-top: 100px;
	background-color: $ghost-white-100;
	position: relative;

	&::after {
		@include media('tablet-portrait-down') {
			width: 20px;
		}
		content: '';
		position: absolute;
		right: 0;
		top: 0;
		width: 56px;
		height: 100%;
		background: linear-gradient(270deg, #F6F7FC 0%, rgba(246, 247, 252, 0) 100%);
		z-index: 10;
	}
}

.pricelist__owl-navigation {
	@include media('tablet-portrait-down') {
		display: none;
	}
	position: absolute;
	top: calc(50% + 100px);
	right: 0;
	transform: translateX(-50%);
	z-index: 100;

	.disabled {
		opacity: 0;
		cursor: auto;
	}

	.owl-next {
		transition: opacity 300ms ease-in-out;

		&:hover {
			opacity: 0.6;
		}
	}
}

.pricelist__title {
	margin-bottom: 85px;
	width: 100%;
	text-align: center;
	em {
		color: $blue-100;
		font-style: normal;
	}
}

.pricelist-labels {
	@include media('tablet-portrait-down') {
		width: 140px;
	}
	width: 380px;	
	background-color: $ghost-white-100;
	padding-top: 327px;
	display: flex;
	flex-wrap: wrap;
	align-content: flex-start;
	z-index: 10;
	position: relative;

	&::after {
		content: '';
		position: absolute;
		top: 0;
		height: 100%;
		width: 500px;
		left: -500px;
		background-color: $ghost-white-100;
	}
}

.pricelist-labels__label {
	@include media('tablet-portrait-down') {
		font-size: 16px;
		white-space: wrap;
		height: 50px;
	}
	font-weight: 700;
	font-size: 21px;
	line-height: 120%;
	font-family: map-get($font-family, 'heading');
	// margin-bottom: 50px;
	width: 100%;
	height: 75px;
	overflow: hidden;
    white-space: nowrap;
}

.pricelist-products {
	width: calc(100% - 380px);

	.owl-stage-outer {
		overflow: visible;
	}

	.owl-stage {
		display: flex;
	}
	.owl-item {
		display: flex;
		flex: 1 0 auto;
	}

	.pricelist-product {
		display: flex;
		flex: 1 0 auto;
		flex-direction: column;
	}
}

.pricelist-products {
	display: flex;
	flex-wrap: wrap;
	background-color: $ghost-white-100;
	position: relative;
}

.pricelist-product {
	@include media('tablet-portrait-down') {
		width: 200px;
	}
	width: 260px;
	border-radius: 20px;
	padding: 0 10px;
	background-color: $white-100;
	border: 3px solid transparent;
	transition: all 300ms ease-in-out;

	&.is-active {
		border-color: $blue-100;
	}
}

.pricelist-product__header {
	padding-top: 40px;
	display: flex;
	flex-wrap: wrap;
	border-bottom: 4px solid #F6F7FC;
	align-content: flex-start;
	height: 285px;
}

.pricelist-product__header-name {
	font-weight: 700;
	font-size: 21px;
	line-height: 120%;
	text-align: center;
	letter-spacing: -0.02em;
	width: 100%;
	font-family: map-get($font-family, 'heading');
	margin-bottom: 10px;
	height: 50px;

	em {
		font-style: normal;
		color: $blue-100;
	}
}

.pricelist-product__header-price {
	width: 100%;
	color: $blue-100;
	font-family: map-get($font-family, 'heading');
	font-weight: 700;
	font-size: 48px;
	line-height: 120%;
	text-align: center;
	letter-spacing: -0.02em;
	height: fit-content;
}

.pricelist-product__header-term {
	width: 100%;
	font-family: map-get($font-family, 'heading');
	font-weight: 700;
	font-size: 14px;
	line-height: 120%;
	text-align: center;
	letter-spacing: -0.02em;
	margin-bottom: 40px;
	height: fit-content;
}

.pricelist-product__header-link {
	width: fit-content;
	text-align: center;
	padding: 0 36px;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 50px;
	color: $blue-100;
	border: 1px solid $blue-100;
	font-weight: 500;
	font-size: 16px;
	line-height: 120%;
	border-radius: 8px;
	margin: auto;
	margin-bottom: 40px;
	transition: all 300ms ease-in-out;

	&:hover {
		opacity: 0.6;
	}
}

.pricelist-product__labels {
	padding-top: 40px;
	display: flex;
	flex-wrap: wrap;
}

.pricelist-product__label {
	@include media('tablet-portrait-down') {
		height: 50px;
		line-height: 100%;
		font-size: 16px;
	}
	width: 100%;
	text-align: center;
	font-weight: 300;
	font-size: 18px;
	line-height: 25px;
	text-align: center;
	margin-bottom: 0px;
	height: 75px;

	&.is-checkmark {
		svg {
			width: 22px;
			height: 22px;
			fill: $blue-100;
		}
	}

	&.is-cross {
		svg {
			width: 22px;
			height: 22px;
			fill: #C4C4C4;
		}
	}
}

.pricelist-go-back-mobile {
	@include media('tablet-portrait-up') {
		display: none;
	}
	position: absolute;
	right: 20px;
	top: 50%;
	transform: translateY(-50%);
	width: 30px;
	height: 30px;
	border-radius: 100px;
	background-color: $dark-purple-100;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	z-index: 1000;
	transition: all 300ms ease-in-out;

	svg {
		width: 14px;
		height: 14px;
		fill: $white-100;
		pointer-events: none;
	}


	&.hidden {
		pointer-events: none;
		opacity: 0;
	}
}