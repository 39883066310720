/**
 * Unicode.
 *
 * Preserve escaped unicode string.
 * @param {String} $string - An unicode string.
 * @return {String} A preserved escaped unicode string
 */
 
// sass-lint:disable quotes
@function unicode($string) {
	@return unquote("\'") + unquote(str-insert($string, '\\', 1)) + unquote("\'");
}
// sass-lint:enable quotes


/**
 * REM.
 *
 * Convert pixel units to REM units.
 * @param {Number} $pixels - Pixel value.
 * @return {*} - A converted `rem` unit.
 */

@function rem($pixels) {
	@if (unitless($pixels)) {
		@error 'Value for `#{$pixels}` should be a `px` unit.';
	}
	@return (($pixels / 10px) * 1rem);
}


/**
 * Column.
 *
 * Calculate given column width.
 * @param {Number} $column - The amount of columns to span.
 * @param {Number} $columns - The amount of columns that can be spanned.
 * @return {String} - Percentage value.
 */

@function column($column, $columns: $grid-columns) {
	@return ($column  * 100% / $columns);
}


/**
 * Color.
 *
 * Get a color value from two levels deep in the `$color` map.
 * @param {$string} $key - Key of the color to get from the map.
 * @param {Number} $value - Value of the key to get from the map.
 * @return {*} A HSLA color value.
 */

@function color($key, $value) {
	@return map-get(map-get($colors, $key), $value);
}
