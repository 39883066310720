// A limited array of values for the `z-index` property to prevent
// Exaggerated amounts and ugly hacks.

$z-index: (
	'0':  0,
	'1':  10,
	'2':  20,
	'3':  30,
	'4':  40,
	'5':  50,
	'6':  60,
	'7':  70,
	'8':  80,
	'9':  90,
	'10': 100
);
