.case-list {
	position: relative;

	.graphic {
		@include media('tablet-portrait-down') {
			width: 400%;
		}
		position: absolute;
		top: -50px;
		width: 125%;
		left: -15%;
		z-index: -1;

		img {
			width: 100%;
		}
	}
}
.case-list__wrap {
	@include media('tablet-portrait-down') {
		margin-bottom: 100px;
	}
	gap: 32px;
	margin-bottom: 200px;
}

.case-list__header {
	margin-top: 70px;
	margin-bottom: 60px;
}

.case-list_categories {
	display: flex;
	flex-wrap: wrap;
	gap: 0 20px;

	span {
		width: 100%;
		margin-bottom: 14px;
		font-weight: 400;
		font-size: 15px;
		line-height: 140%;
		letter-spacing: -0.02em;
		color: $purple-100
	}
}

.case-list__title {
	margin-bottom: 40px;


	em {
		color: $blue-100;
		font-style: normal;
	}
}

.case-list__editor {
	margin-bottom: 32px;

	p {
		color: $purple-100;
	}
}

.case-filter {
	padding: 16px 37px;
	border: 1px solid $purple-100;
	color: $purple-100;
	font-weight: 500;
	font-size: 16px;
	line-height: 120%;
	background-color: transparent;
	transition: all 300ms ease-in-out;
	border-radius: 8.4px;
	margin-bottom: 20px;

	&:hover, &.is-active {
		background-color: $blue-100;
		color: $white-100;
		border-color: $blue-100;
	}
}

.case-list__google {
	display: flex;
	align-items: center;
	margin-left: 20px;
	
	img {
		height: 12px;
		width: auto !important;
		object-fit: contain;
	}
	span {
		font-size: 11px;
		line-height: 120%;
		letter-spacing: -0.02em;
		margin-left: 7px;
	}
}

.case-single__google {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-left: 20px;
	
	img {
		height: 12px;
		width: auto !important;
		object-fit: contain;
	}
	span {
		font-size: 11px;
		line-height: 120%;
		letter-spacing: -0.02em;
		margin-left: 7px;
		color: $white-100;
	}
}