/**
 * Vertical Rhythm.
 */
 
@for $i from 0 through 10 {
	$i: $i * 8;

	.vr-#{$i} {
		margin-bottom: #{$i}px;
	}
}
