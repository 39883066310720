/**
 * Example of a flex based column grid layout in practice:
 *
 * <div class="row">
 *   <div class="col col-xs-6 col-sm-12"></div>
 *   <div class="col col-xs-6 col-sm-12"></div>
 * </div>
 *
 * This example will create a row with two columns each filling half the width
 * of the row on all media, but will convert to a full width column on the
 * small `-sm` breakpoint alias, which is mobile only. Refer to
 * `settings/_definitions.scss` for all breakpoint alias.
 */

/**
 * Flex object.
 *
 * Tip: Use flex on a column to make the box the same height as the column.
 */

.flex {
	display: flex;
}

.flex-wrap {
	flex-wrap: wrap;
}


/**
 * Flex object with a column based layout system.
 */

.row {
	display: flex;
	flex: 0 1 auto;
	flex-flow: row wrap;
	margin: 0 -#{($grid-gap/2)};
}


/**
 * Box, the inside of a column.
 */

.box {
	flex: 1;
}


// Loop through all the keys in the `$breakpoints` variable map defined in
// `settings/_definitions.scss`.
@each $key, $value in $breakpoints {

	// Create a media query for every key in `$breakpoints`.
	@include media($value) {

		// Iterate from 1 through the amount of grid columns defined by
		// `$grid-columns` in `settings/_definitions.scss`.
		@for $i from 1 through $grid-columns {

			/**
			 * Column #{$i} for the `#{$key}` breakpoint.
			 */

			.col-#{$key}-#{$i} {
				flex: 0 1 auto;
				width: column($i);
				padding: 0 ($grid-gap/2);
			}


			// Output an offset utility class if the iterator is less than the amount
			// of grid columns defined by `$grid-columns` in
			// `settings/_definitions.scss`.
			@if ($i < $grid-columns) {

				/**
				 * Offset #{$i} for the `#{$key}` breakpoint.
				 */

				.offset-#{$key}-#{$i} {
					margin-left: column($i);
				}
			}
		}


		// Loop through all values for the `align-items` property.
		@each $value in stretch, center, flex-start, flex-end, baseline {

			/**
			 * Align `#{$value}` for the #{$key} breakpoint.
			 */

			.align-#{$key}-#{$value} {
				align-items: $value;
			}
		}


		// Loop through all values for the `justify-content` property.
		@each $value in start, center, space-between, space-around, space-evenly {

			/**
			 * Justify `#{$value}` for the #{$key} breakpoint.
			 */

			.justify-#{$key}-#{$value} {
				justify-content: $value;
			}
		}


		// Loop through every direction from left to right.
		@each $direction in left, right {

			/**
			 * Bleed #{$direction} for the #{$key} breakpoint.
			 */

			.bleed-#{$key}-#{$direction} {
				padding-#{$direction}: 0;
			}
		}


		/**
		 * Order first for the `#{$key}` breakpoint.
		 */

		.first-#{$key} {
			order: -1;
		}


		/**
		 * Order last for the `#{$key}` breakpoint.
		 */

		.last-#{$key} {
			order: 1;
		}


		/**
		 * Reverse order for the `#{$key}` breakpoint.
		 */

		.reverse-#{$key} {
			flex-direction: row-reverse;
		}
	}
}
