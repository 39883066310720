.breadcrumbs {
	margin-top: 10px;
	margin-bottom: 10px;
	> .wrapper--extra {
		max-width: calc(1520px + 100px);
	}
}

.content-breadcrumbs__links {
	@include media('tablet-portrait-down') {
		overflow-x: scroll;
	}
}

.content-breadcrumbs__pages {
	display: flex;
	flex-wrap: nowrap;
	align-items: center;

	.content-breadcrumbs__seperator {
		@include media('tablet-portrait-down') {
			margin: 0 4px;
		}
		display: flex;
		align-items: center;
		margin: 0 8px;
	}
	svg {
		@include media('tablet-portrait-down') {
			width: 12px;
			height: 12px;
		}
		width: 20px;
		height: 20px;
	}

	span {
		@include media('tablet-portrait-down') {
			font-size: 12px;
			width: max-content;
		}
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		
	}

	a {
		transition: all 300ms ease-in-out;

		&:hover {
			opacity: 0.6;
		}
	}
}