.case-item {
	@include media('tablet-portrait-down') {
		padding: 20px;
		width: 320px;
	}
	padding: 40px;
	background-color: $ghost-white-100;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
	border-radius: 10px;
	border: 1px solid rgba(29, 1, 88, 0.04);
	display: flex;
	flex-wrap: wrap;
	width: 600px;
}

.case-item__user {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 25px;
}

.case-item__user-person {
	@include media('tablet-portrait-down') {
		width: 48px;
		height: 48px;
	}
	width: 80px;
	height: 80px;
	margin-right: 20px;

	img {
		@include media('tablet-portrait-down') {
			width: 48px;
			height: 48px;
		}
		width: 80px;
		height: 80px;
		border-radius: 100px;
		border: 3px solid $blue-100;
	}
}

.case-item__user-details {
	width: calc(100% - 100px);
	display: flex;
	flex-wrap: wrap;
	align-content: center;
}

.case-item__user-details-name {
	@include media('tablet-portrait-down') {
		font-size: 16px;
		line-height: 22px;
	}
	width: 100%;
	color: $purple-100;
	font-size: 800;
	line-height: 27px;
	font-size: 20px;
	margin-bottom: 4px;
}

.case-item__user-details-function {
	@include media('tablet-portrait-down') {
		font-size: 14px;
		line-height: 19px;
	}
	width: 100%;
	color: $blue-100;
	font-weight: 500;
	font-size: 16px;
	line-height: 22px;
}

.case-item__quote {
	@include media('tablet-portrait-down') {
		margin-bottom: 12px;
	}
	margin-bottom: 34px;
}

.case-item__review-number {
	color: $black-100;
	font-size: 14px;
	line-height: 26px;
	margin-right: 10px;
	font-weight: 500px;
}

.case-item__review {
	@include media('tablet-portrait-down') {
		margin-bottom: 12px;
	}
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	margin-bottom: 34px;
	align-content: center;
}

.case-item__review-stars {
	display: flex;
	flex-wrap: wrap;
	align-content: center;

	svg {
		margin-right: 4px;
		width: 14px;
		height: 14px;
		fill: #FDB002;
	}
}

.case-item__link {
	a {
		@include media('tablet-portrait-down') {
			font-size: 16px;
			line-height: 19px;
		}
		font-weight: 500;
		font-size: 18px;
		line-height: 120%;
		letter-spacing: -0.02em;
		text-decoration-line: underline;
		font-family: map-get($font-family, 'heading');
		color: $purple-100;
		transition: all 300ms ease-in-out;

		&:hover {
			opacity: 0.6;
		}
	}
}