/**
 * The content constraint is a constraint for all the content within
 * the document. It can be manipulated by CSS or JavaScript without
 * influencing the body.
 */

.content-constraint {
	position: relative;
	min-height: 100vh;
	overflow: hidden;
	padding-top: 101px;
	
	&.is-scrolling {
		margin-top: 112px;
	}
}
