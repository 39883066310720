/**
 * Color utility classes.
 *
 * Automatically generated color and background color utility classes based on every color definition in `settings/_color.scss`.
 * Loop through every `$key` and `$map` in the `$colors` variable map defined in `settings/_color.scss`.
 */
 
@each $key, $map in $colors {

	// Loop through every `$shade` and `$rgb` in `$map`.
	@each $shade, $rgb in $map {

		// Loop through every `color` and `background-color` rules.
		@each $rule in color, background-color {

			/**
			 * `#{$rule}` #{$rgb}-#{$shade} color utility.
			 */

			.#{$rule}-#{unquote($key)}-#{unquote($shade)} {
				#{$rule}: $rgb;
			}
		}
	}
}
