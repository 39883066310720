.content-header {
	@include media('tablet-portrait-down') {
		margin-bottom: 60px;
	}
	margin-top: 25px;
	margin-bottom: 100px;
	position: relative;

	> .wrapper--extra {
		max-width: calc(1520px + 100px);
	}

	&.content-header__color-white {
		.content-header__title {
			color: $white-100;
			font-weight: 700;
		}
		
		color: $white-100
	}

	.graphic {
		@include media('tablet-portrait-down') {
			bottom: -40px;
		}
		z-index: -1;
		position: absolute;
		bottom: -140px;
		left: -25%;
		width: 150%;

		img {
			width: 100%;
		}
	}
}

.content-header__wrapper {
	@include media('tablet-portrait-down') {
		padding: 0 35px;
	}
	@include media('phone') {
		padding: 16px;
	}
	position: relative;
	z-index: 10;
	padding: 0 50px;

	&::after {
		content: '';
		height: 333px;
		background-position: bottom;
		z-index: -1;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		background-repeat: no-repeat;
		background-size: contain;
	}
}

.content-header__background {
	z-index: -1;
	border-radius: 16px;

	img {
		@include media('phone') {
			object-position: 65%;
		}
		border-radius: 18px;
		z-index: -1;
	}

	&.has-gradient {
		&::after {
			@include media('phone') {
				background: linear-gradient(168.47deg, #DED7FD 8.45%, rgba(241, 238, 254, 0.840208) 44.77%, rgba(246, 247, 252, 0.11) 61.01%), linear-gradient(0deg, rgba(10, 1, 65, 0.5) 0%, rgba(10, 1, 65, 0) 56.06%), linear-gradient(0deg, rgba(10, 1, 65, 0.5) 0%, rgba(10, 1, 65, 0) 56.06%);	
			}
			content: '';
			background: linear-gradient(0deg, rgba(10, 1, 65, 0.5) 0%, rgba(10, 1, 65, 0) 56.06%), linear-gradient(105.69deg, #DED7FD 3.66%, rgba(241, 238, 254, 0.840208) 28.73%, rgba(246, 247, 252, 0.11) 64.76%);
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: -1;
			border-radius: 16px;
		}
	}
}

.content-header__content {
	@include media('tablet-portrait-down') {
		padding-top: 90px;
	}
	@include media('phone') {
		padding-top: 0;
		padding-bottom: 110px;
	}
	padding-top: 85px;
	padding-bottom: 100px;
	max-width: 700px;
}

.content-header__title {
	@include media('tablet-portrait-down') {
		font-size: 46px;
		line-height: 50px;
		margin-bottom: 16px;
	}
	margin-bottom: 40px;
	font-weight: 400;
	font-size: 72px;
	line-height: 120%;

	em {
		font-style: normal;
		color: $blue-100;
	}
}

.content-header__editor {
	margin-bottom: 40px;
	p {
		@include media('tablet-portrait-down') {
			font-size: 16px;
		}
		font-weight: 500;
		font-size: 18px;
		line-height: 120%;
	}
}

@keyframes responsive-height {
	100% {
		height: auto;
	}
}
.content-header__logos {
	@include media('phone') {
		gap: 0;
		width: 140px;
		height: auto;
		margin: auto;
		padding-bottom: 0;
	}
	display: flex;
	justify-content: space-between;
	padding-bottom: 50px;
	position: relative;
	gap: 24px;
	height: 106px;
	animation: responsive-height 0s forwards;
	animation-delay: 500ms;

	@include media('tablet-portrait-up') {
		&:hover {
			cursor: pointer;
			
			.logo-slider__logo {
				filter: blur(5.5px);
			}
	
			.content-header__link  {
				opacity: 1;
				top: calc(50% - 30px);
			}
		}
	}

	.owl-dots {
		display: flex;
		justify-content: center;
		gap: 5px;
		margin-top: 12px;
		.owl-dot {
			width: 9px;
			height: 9px;
			background-color: $white-100;
			opacity: 0.3;
			border-radius: 10px;
			transition: all 300ms ease-in-out;

			&.active {
				opacity: 1;
			}
		}
	}
}

.logo-slider__logo {
	@include media('phone') {
		height: 50px;
		display: flex;
		justify-content: center;

		img {
			object-fit: contain;
			object-position: center;
		}
	}
	transition: all 300ms ease-in-out;
}

.content-header__logo-hover {
	position: absolute;
	width: 100%;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);

	.content-header__link {
		opacity: 0;
		transition: all 300ms ease-in-out;
		position: absolute;
		left: 50%;
		top: calc(50% - 25px);
		transform: translate(-50%, -50%);
		background-color: transparent;
		border: 1px solid $white-100;
		color: $white-100;
	
		&:hover {
			background-color: $white-100;
			color: $purple-100;
		}
	}
}