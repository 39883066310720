/**
 * Font family map.
 */

$font-family: (
	'default': 'Lexend',
	'heading': 'Space Grotesk',
);


/**
 * Font size map.
 */

$font-size: (
	'h1': 72px,
	'h2': 34px,
	'h3': 28px,
	'h4': 18px,
	'h5': 18px,
	'p': 15px
);


/**
 * Line height map.
 */

$line-height: (
	'h1': 86px,
	'h2': 41px,
	'h3': 34px,
	'h4': 22px,
	'h5': 22px,
	'p': 24px
);

/**
* Mobile.
**/

/**
 * Font size map.
 */

 $mobile-font-size: (
	'h1': 46px,
	'h2': 23px,
	'h3': 19px,
	'h4': 16px,
	'h5': 16px,
	'p': 13px
);


/**
 * Line height map.
 */

$mobile-line-height: (
	'h1': 55px,
	'h2': 28px,
	'h3': 23px,
	'h4': 19px,
	'h5': 19px,
	'p': 21px
);