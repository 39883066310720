.form {
	@include media('tablet-portrait-down') {
		margin-bottom: 60px;
	}
	position: relative;
	margin-top: 40px;
	margin-bottom: 100px;

	&::after {
		@include media('tablet-portrait-down') {
			height: calc(100% - 50px);
			top: 50px;
		}
		content: '';
		width: 100%;
		height: calc(100% - 160px);
		position: absolute;
		left: 0;
		top: 80px;
		background-color: $purple-100;
		z-index: -1;
	}
}

.form__hidden_field {
	display: none;
}

.form-graphic {
	@include media('tablet-portrait-down') {
		width: 300%;
		right: inherit;
		left: -50%;
		bottom: -200px;
	}
	position: absolute;
	bottom: 80px;
	right: -30px;
	width: 100%;
	max-height: 400px;
	overflow: hidden;
}

.form__info {
	@include media('tablet-portrait-down') {
		width: 100%;
		order: 2;
		margin-top: 85px;
		padding: 0 40px;
		padding-bottom: 100px;
	}
	width: 40%;
	margin-top: 200px;
	display: flex;
	flex-wrap: wrap;
	z-index: 1;
}

.form__info-title {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	color: $white-100;
	margin-bottom: 16px;

	&:not(.is-location) {
		svg {
			path {
				fill: $blue-100;
			}
		}
	}

	svg {
		width: 30px;
		height: 30px;
		margin-right: 13px;
	}
}

.form__info-content {
	@include media('tablet-portrait-down') {
		font-size: 15px;
	}
	font-weight: 500;
	font-size: 18px;
	line-height: 120%;
	letter-spacing: -0.02em;
	z-index: 1;

	a {
		@include media('tablet-portrait-down') {
			font-size: 15px;
		}
		text-decoration: underline;
		transition: all 300ms ease-in-out;

		&:hover {
			opacity: 0.6;
		}
	}

	p {
		@include media('tablet-portrait-down') {
			font-size: 15px;
		}
		margin-bottom: 20px;
	}
}

.form__info-wrapper {
	color: $ghost-white-100;
}

.form__info-phone, .form__info-location {
	@include media('tablet-portrait-down') {
		margin-bottom: 60px;
	}
	margin-bottom: 90px;
}

.form__content {
	@include media('tablet-portrait-down') {
		width: 100%;
		order: 1;
	}
	width: 60%;
	background-color: $white-100;
	border-radius: 16px;
	z-index: 1;
}

.form__header {
	@include media('tablet-landscape-down') {
		padding: 30px 20px;
	}
	background-color: #ECEFFF;
	border-radius: 16px 16px 0px 0px;
	padding: 40px 50px;
}

.form__header-title {
	@include media('tablet-portrait-down') {
		font-size: 23px;
		line-height: 27px;
	}
	margin-bottom: 16px;
	font-weight: 600;
	font-size: 56px;
	line-height: 68px;
}

.form__form {
	@include media('tablet-landscape-down') {
		padding: 30px 20px;
	}
	padding: 40px 50px;
	box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.08);
	border-radius: 16px;
	
	.gform_validation_container, .gfield_visibility_hidden {
		display: none !important;
	}

	.gfield {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		margin-bottom: 38px;
		gap: 10px;

		.ginput_container {
			max-width: 366px;
			width: 100%;
			height: 52px;
		}

		.ginput_container_textarea {
			max-width: 366px;
			width: 100%;
			height: 135px;
			border-color: $purple-100;

			textarea {
				height: 135px;
				border-color: $purple-100;
				border-radius: 8px;
			}
		}

		.gfield_description {
			@include media('tablet-landscape-down') {
				margin-left: 0;
				margin-top: 4px;
				width: 100%;
			}
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			font-weight: 300;
			font-size: 15px;
			line-height: 120%;
		}

		.gform_validation_container {
			display: none !important;
		}

		.ginput_container_consent {
			@include media('tablet-portrait-down') {
				align-items: flex-start;
			}
			height: fit-content !important;
			display: flex;
			flex-wrap: wrap;
			max-width: 100%;
			align-items: center;

			input {
				display: flex;
				width: 20px;
				height: 20px;
				border-radius: 4px;
				border: 1px solid $purple-100;
				margin-right: 17px;
			}

			.gfield_consent_label {
				width: calc(100% - 37px);
				margin: 0;
				color: $purple-100;
			}

			span.gfield_required_asterisk {
				display: none;
			}
		}

		.gfield_label {
			position: absolute;
			top: -25%;
			left: 0px;
			font-weight: 400;
			font-size: 15px;
			line-height: 160%;
			padding: 0 5px;
			background-color: $white-100;
			color: $purple-100;
		}
	}

	.gsection {
		margin-bottom: 0;
		height: 1px;
		width: 100%;
		max-width: 100%;
		background-color: $purple-20;
		margin-top: 32px;
		margin-bottom: 32px;
	}

	input {
		background-color: $white-100;
		border-radius: 8px;
		border: 1px solid $purple-100;
		color: $purple-100;
		height: 52px;
	}

	.gform_button {
		border-radius: 0 8px 8px 0;
		background-color: $purple-100;
		color: $white-100;
		font-weight: 500;
		font-size: 15px;
		line-height: 120%;
		height: 52px;
		padding: 0px 38px;
		transition: all 300ms ease-in-out;

		&:hover {
			opacity: 0.8;
		}
	}

	.gform_button {
		border-radius: 8px;
		background-color: $blue-100;
	}
}

.form__header-product {
	@include media('tablet-landscape-down') {
		margin-top: 50px;
	}
	@include media('tablet-portrait-down') {
		margin-top: 30px;
	}
	margin-top: 72px;
}

.form-product {
	display: flex;
	flex-wrap: wrap;
}

.form-product__header {
	@include media('tablet-landscape-down') {
		width: 100%;
		border-right: 0;
		margin-bottom: 20px;
	}
	width: 170px;
	display: flex;
	flex-wrap: wrap;
	align-content: flex-start;
	border-right: 1px solid $blue-100;
}

.form-product__header-name {
	font-weight: 700;
	font-size: 18px;
	line-height: 120%;
	letter-spacing: -0.02em;
	height: fit-content;
	margin-bottom: 7px;
	text-align: left;

	em {
		font-style: normal;
		color: $blue-100;
	}
}

.form-product__header-price {
	width: 100%;
	font-weight: 700;
	font-size: 48px;
	line-height: 120%;
	text-align: left;
	letter-spacing: -0.02em;
	color: $blue-100;
	font-family: map-get($font-family, 'heading');
}

.form-product__header-term {
	font-family: map-get($font-family, 'heading');
	font-weight: 700;
	font-size: 14px;
	line-height: 120%;
	text-align: center;
	letter-spacing: -0.02em;
	text-align: left;
}

.form-product__labels {
	@include media('tablet-landscape-down') {
		width: 100%;
	}
	width: calc(100% - 170px);
}

.form-product__labels {
	@include media('tablet-landscape-down') {
		padding-left: 0;
		border-top: 1px solid $blue-100;
		padding-top: 30px;
	}
	padding-left: 35px;
	display: flex;
	flex-wrap: wrap;
	align-content: flex-start;
	gap: 35px 0;
}

.form-product__label {
	@include media('tablet-portrait-down') {
		width: 100%;
	}
	width: 50%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	height: fit-content;

	.label {
		display: flex;
		font-weight: 700;
		font-size: 15px;
		line-height: 120%;
		font-family: map-get($font-family, 'heading');
		width: calc(100% - 100px);
		margin-right: 20px;
	}

	.value {
		display: flex;
		width: 80px;

		svg {
			width: 20px;
			height: 20px;
			fill: $blue-100;
		}
	}
}

#field_1_13 .gfield_label {
	margin-bottom: 0;
    top: -8%;
}