.navigation-mobile {
	@include media('tablet-landscape-down') {
		height: calc(100% - 101px);
		margin-top: 101px;
		display: flex;
		flex-wrap: wrap;
		align-content: flex-start;
	}

	position: fixed;
	top: -100vh;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: calc(100% - 100px);
	margin-top: 100px;
	transition: all 500ms ease;
	background: $white-100;
	opacity: 0;
	z-index: 999;
	overflow-x: hidden;

	&.is-active {
		top: 0;
		opacity: 1;

		.navigation__footer {
			display: flex;
			pointer-events: all;
		}
	}

	&::-webkit-scrollbar {
		display: none;
	}

	.wrapper {
		height: 100%;
	}
}


/**
 * Main menu.
 */
 .navigation-mobile {
	.navigation__items {
		height: calc(100% - 310px);
		width: 100%;
		margin-top: 40px;
		overflow-x: scroll;
	}
	.navigation__mobile-header {
		background-color: $ghost-white-100;
		box-shadow: inset 0px -4px 22px rgba(6, 1, 65, 0.04);
		width: 100%;
		height: 110px;
		display: flex;
		align-items: center;
		position: relative;

		.navigation__mobile-socials {
			display: flex;
			flex-wrap: wrap;
			gap: 12px;
			
			.footer__bottom-contact-item {
				height: 25px;
				width: 25px;

				svg {
					width: 10px;
					height: 10px;
				}
			}
		}

		.navigation__mobile-default {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: space-between;
			width: 100%;

			> a {
				font-weight: 400;
				font-size: 15px;
				line-height: 120%;
				color: $purple-100;
				font-family: map-get($font-family, 'heading');
				display: flex;
				flex-wrap: wrap;
				align-items: center;

				svg {
					width: 24px;
					height: 24px;
					margin-right: 10px;
				}
			}
		}
	}

	.navigaton__mobile-back {
		display: none;
	}

	.navigation__item {
		width: 100%;
		padding: 8px 0;
		margin-bottom: 20px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		position: relative;

		a {
			font-weight: 500;
			font-size: 15px;
			line-height: 19px;
			color: $purple-100;
		}

		.submenu {
			opacity: 0;
			top: 235px;
			left: 200%;
			pointer-events: none;
		}

		> span {
			margin-left: auto;
			display: flex;

			svg {
				width: 16px;
				height: 16px;
				fill: $purple-100;
				transform: rotate(-90deg);
			}
		}

		&:nth-last-child(2) {
			padding-bottom: 250px;
		}
		&:last-child {
			display: none;
		}

		&.is-active {
			.submenu {
				top: 235px;
				left: 0;
				opacity: 1;
				pointer-events: all;
			}
		}
	}
 }

.navigation-mobile .submenu {
	position: fixed;
	transition: all 400ms ease-in-out;
	background-color: $white-100;
	width: 100%;
	overflow-x: scroll;
	height: calc(100vmax - 411px);
	z-index: 100;
	@extend .wrapper;

	.submenu__blocks {
		margin-top: 30px;

		.submenu__block:last-child {
			margin-bottom: 200px;
		}
	}

	.submenu__block-links {
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		span {
			display: flex;
			margin-right: 14px;
		}

		svg {
			width: 15px;
			height: 15px;
			fill: $purple-100;
		}

		a {
			display: flex;
			font-weight: 400;
			font-size: 15px;
			line-height: 19px;
			letter-spacing: 0.04em;
			color: $purple-100;
			width: 100%;

			p {
				line-height: 19px !important;
			}
		}
	}

	.submenu__block {
		margin-bottom: 33px;
	}
}

.navigation__mobile-header.is-active {
	.navigation__mobile-default {
		display: none;
	}

	.navigaton__mobile-back  {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
		width: 100%;

		p {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-items: center;
			width: 100%;
		}

		svg {
			width: 14px;
			height: 14px;
			fill: $purple-100;
		}

		span {
			color: $purple-100;
			font-weight: 400;
			font-size: 15px;
			line-height: 19px;
			letter-spacing: 0.04em;
		}
	}
}

.submenu__lastclicked {
	@include media('tablet-landscape-down') {
		display: flex;
	}
	display: none;
	font-weight: 500;
	font-size: 12px;
	line-height: 15px;
	letter-spacing: 0.04em;
	color: $purple-100;
	margin-bottom: 34px;
	opacity: 0.3;
}


.submenu {
	.navigation__footer {
		padding: 0;
	}
}
.navigation__footer {
	@include media('tablet-landscape-down') {
		flex-wrap: wrap;
		position: relative;
		bottom: 0;
		background-color: $white-100;
		z-index: 1000;
	}
	display: none;
	width: 100%;
	margin-top: auto;
	align-content: flex-end;
	padding: 0;
	pointer-events: none;
	margin-top: 80px;

	.graphic {
		@include media('tablet-landscape') {
			.mobile {
				display: none;
			}

			.tablet {
				display: flex;
				opacity: 0.5;
			}
		}

		@include media('tablet-portrait-down') {
			.mobile {
				display: flex;
				height: 180px;
			}

			.tablet {
				display: none;
			}
		}
		width: 100%;
		height: 200%;
		position: absolute;
		bottom: 0;
		z-index: -1;
		background-color: $white-100;

		img {
			width: 100%;
			height: 110px;
			object-fit: cover;
			object-position: top;
			opacity: 0.5;
		}
		pointer-events: none;
	}

	&::after {
		content: '';
		top: -280px;
		left: 0;
		width: 100%;
		pointer-events: none;
		height: 200px;
		background: linear-gradient(360deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
		position: absolute;
	}
}

.navigation__footer-link {
	color: $white-100 !important;
	width: 100%;
	text-align: center;
}

.navigation__footer-wrap {
	background-color: $white-80;
	padding: 16px 20px;
	width: 100%;
}




/**
 * Disable scroll.
 */

body.is-active {
	overflow: hidden;

	.header::before {
		bottom: 0;
	}
}
