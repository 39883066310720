.case-list-item {
	@include media('tablet-portrait-down') {
		width: 100%;
		height: 400px;
	}
	width: calc(50% - 17px);
	position: relative;
	height: 550px;
	border-radius: 13px;
	display: flex;
	flex-wrap: wrap;
	overflow: hidden;
	z-index: 1;

	&:hover {
		.case-list-item__image img {
			transform: scale(1.03);
		}
	}
}

.case-list-item__background {
	position: absolute;
	height: 100%;
	width: 100%;
	border-radius: 13px;

	&::before {
		content: '';
		top: 0;
		left: 0;
		width: 100%;
		height: 200px;
		position: absolute;
		background: linear-gradient(180deg, #0A0141 0%, rgba(18, 1, 88, 0) 100%);
		opacity: 0.3;
		border-radius: 13px;
		z-index: -1;
	}

	&::after {
		content: '';
		bottom: 0;
		left: 0;
		width: 100%;
		height: 200px;
		position: absolute;
		background: linear-gradient(180deg, #0A0141 0%, rgba(18, 1, 88, 0) 100%);
		opacity: 0.8;
		transform: rotate(-180deg);
		border-radius: 13px;
		z-index: -1;
	}
}

.case-list-item__image {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 13px;
	z-index: -2;

	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 13px;
		transition: all 300ms ease-in-out;
	}
}

.case-list-item__logo {
	position: absolute;
	top: 26px;
	right: 32px;

	img {
		max-height: 100px;
		max-width: 200px;
		height: 100%;
		width: 100%;
		object-fit: contain;
	}
}

.case-list-item__content {
	z-index: 10;
	margin-top: auto;
	width: 100%;
	padding: 32px;

	* {
		z-index: 10;
	}
}

.case-list-item__intro {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	padding-bottom: 20px;
	margin-bottom: 20px;
	border-bottom: 1px solid $white-100;
}

.case-list-item__name {
	@include media('tablet-portrait-down') {
		font-size: 14px;
	}
	padding-right: 12px;
	margin-right: 12px;
	border-right: 1px solid $white-70;
	font-weight: 700;
	font-size: 18px;
	line-height: 160%;
	color: $ghost-white-100;
}

.case-list-item__category {
	@include media('tablet-portrait-down') {
		font-size: 14px;
	}
	font-weight: 400;
	font-size: 18px;
	line-height: 160%;
	color: $ghost-white-100;
}

.case-list-item__title {
	@include media('tablet-portrait-down') {
		font-size: 19px;
	}
	color: $ghost-white-100;
	font-weight: 700;
	font-size: 28px;
	line-height: 120%;
	letter-spacing: -0.02em;
	font-family: map-get($font-family, 'heading');
}