/**
 * Heading.
 */

h1,
h2,
h3,
h4,
%heading {
	font-family: map-get($font-family, 'heading');
	color: $dark-purple-100;
	font-weight: 700;
}

/**
  * Heading 1.
*/
 
h1,
.heading-1 {
	@include media('tablet-portrait-down') {
	  font-size: map-get($mobile-font-size, 'h1');
		line-height: map-get($mobile-line-height, 'h1');
	}
  
	font-size: map-get($font-size, 'h1');
	line-height: map-get($line-height, 'h1');
}
  
/**
 * Heading 2.
 */
  
h2,
%heading-2,
.heading-2 {
	@include media('tablet-portrait-down') {
		font-size: map-get($mobile-font-size, 'h2');
		line-height: map-get($mobile-line-height, 'h2');
	}
  
	font-size: map-get($font-size, 'h2');
	line-height: map-get($line-height, 'h2');
}
  
  
/**
 * Heading 3.
 */
  
h3,
%heading-3,
.heading-3 {
	@include media('tablet-portrait-down') {
		font-size: map-get($mobile-font-size, 'h3');
		line-height: map-get($mobile-line-height, 'h3');
	}
  
	font-size: map-get($font-size, 'h3');
	line-height: map-get($line-height, 'h3');
}
  
  
/**
* Heading 3.
*/
  
h4,
%heading-4 {
	@include media('tablet-portrait-down') {
		font-size: map-get($mobile-font-size, 'h4');
		line-height: map-get($mobile-line-height, 'h4');
	}
  
	font-size: map-get($font-size, 'h4');
	line-height: map-get($line-height, 'h4');
}
  
  
/**
* Heading 3.
*/
  
h5,
%heading-5 {
	@include media('tablet-portrait-down') {
		font-size: map-get($mobile-font-size, 'h5');
		line-height: map-get($mobile-line-height, 'h5');
	}
  
	font-size: map-get($font-size, 'h5');
	line-height: map-get($line-height, 'h5');
}


/**
 * Subheading.
 */
p {
	@include media('tablet-portrait-down') {
		font-size: map-get($mobile-font-size, 'p');
		line-height: map-get($mobile-line-height, 'p');
	}

	font-size: map-get($font-size, 'p');
	line-height: map-get($line-height, 'p');

	&.is-white {
		color: $white-100;
	}
}