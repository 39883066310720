/**
 * Every conceivable HTML element within a `.cms-output` wrapper.
 *
 * 1. Exclude last childs.
 */

.cms-output {
	p {
		&:not(:last-child) {
			margin-bottom: 2em;
		}
	}

	h2,
	h3,
	h4 {
		margin-bottom: 30px;
	}

	h5 {
		margin-bottom: 20px;
	}

	.is-intro {
		@include media('tablet-portrait-down') {
			@include font(18px, 24px);
		}

		@include font(20px, 24px);
		font-family: map-get($font-family, 'heading');
		font-weight: 700;
		margin-bottom: 40px;
		display: flex;
	}

	img {
		&.aligncenter {
			display: block;
			margin: 0 auto;
		}

		&.alignright {
			display: block;
			margin: 0 0 0 auto;
		}
	}

	table {
		word-break: break-word;

		td {
			vertical-align: top;
		}
	}

	ul,
	ol {
		list-style-type: square;
		margin-left: 20px;
		margin-bottom: 1em;

		li {
			margin-bottom: 15px;
		}
	}

	ol {
		list-style-type: decimal;
	}

	strong {
		font-weight: bold;
	}

	a {
		text-decoration: underline;
		color: $purple-100;
		transition: all 250ms ease;

		&:hover {
			opacity: 0.6;
		}
	}

	blockquote {
		position: relative;
		padding: 40px 45px 20px 45px;
		color: $purple-100;
	}

	.wp-caption {
		margin-bottom: 20px;

		img {
			border-radius: 10px;
		}

		.wp-caption-text {
			@include font(16px, 20px);
			color: $purple-100;
			letter-spacing: 0.44px;
			margin-top: 10px;
		}
	}

	.border-top-right {
		img {
			border-radius: 15px;
			border-top-right-radius: 300px;
		}
	}

	.border-top-left {
		img {
			border-radius: 15px;
			border-top-left-radius: 300px;
		}
	}

	.border-bottom-right {
		img {
			border-radius: 15px;
			border-bottom-right-radius: 300px;
		}
	}

	.border-bottom-left {
		img {
			border-radius: 15px;
			border-bottom-left-radius: 300px;
		}
	}
}
