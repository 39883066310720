.header-secondary {
	margin-top: 40px;
	padding-bottom: 70px;
	position: relative;

	&.header-secondary--background-white {
		> .wrapper--extra {
			background-color: $white-100;
			border-radius: 16px;
		}
	}

	&.header-secondary--background-dark-blue {
		.header-secondary__wrapper {
			@include media('tablet-portrait-down') {
				padding-left: 0;
				margin: 0 20px;
				padding-top: 30px;
			}
			@include media('phone') {
				margin: 0;
			}
			background-color: $dark-purple-100;
			border-radius: 16px;
			padding-left: 20px;
		}

		
		.header-secondary__text {
			@include media('desktop') {
				padding: 30px;
				padding-right: 0;
			}
			@include media('tablet-landscape-down') {
				padding: 20px;
			}
		}

		> .wrapper--extra {
			color: $ghost-white-100;

			.header-secondary__title {
				color: $ghost-white-100;
			}

			.header-secondary__link-secondary {
				background: linear-gradient(253.09deg, rgba(246, 247, 252, 0.25) -7.53%, rgba(246, 247, 252, 0.25) 97.04%);
				color: $ghost-white-100;
				border-color: $ghost-white-100;

				&:hover {
					background-color: $white-100;
					color: $purple-100;
				}
			}
		}

		
		.header-secondary__see-more {
			bottom: -40px;
		}

		@keyframes scroll-jump2 {
			0% {
				height: 10px;
			}
		
			50% {
				height: 30px;
			}
		
			70% {
				height: 10px;
			}
		
			100% {
				height: 30px;
			}
		}
	}
}

.header-secondary__wrapper {
	display: flex;
	flex-wrap: wrap;
}

.header-secondary__text {
	@include media('tablet-portrait-down') {
		width: 100%;
		margin-bottom: 30px;
	}
	width: calc(50%);
	display: flex;
	flex-wrap: wrap;
	align-content: center;
}

.header-secondary__title {
	@include media('tablet-portrait-down') {
		margin-bottom: 20px;
	}
	line-height: 120%;
	margin-bottom: 40px;

	em {
		font-style: normal;
		color: $blue-100;
	}
}

.header-secondary__links {
	gap: 20px 0;
	display: flex;
	flex-wrap: wrap;
}

.header-secondary__link {
	@extend .button--primary;
	margin-right: 20px
}

.header-secondary__link-secondary {
	@extend .button--secondary;
	border: 1px solid $purple-100;
}

.header-secondary__editor {
	margin-bottom: 40px;
}

.header-secondary__image {
	@include media('tablet-portrait-down') {
		width: 100%;
	}
	width: calc(50% - 110px);
	margin-left: auto;

	img {
		@include media('tablet-portrait-down') {
		border-radius: 16px;
		}
		width: 100%;
		height: 100%;
		border-radius: 0px 16px 16px 0px;
	}
}

.header-secondary__see-more {
	@include media('tablet-portrait-down') {
		display: none;
	}
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	text-align: center;


	span {
		font-weight: 400;
		font-size: 18px;
		line-height: 160%;
		width: 100%;
		margin-bottom: 4px;
	}

	&::after {
		content: '';
		display: block;
		width: 2px;
		height: 70px;
		background-color: $blue-100;		
		border-radius: 5px;
	}

	&::before {
		content: '';
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		display: block;
		width: 2px;
		height: 70px;
		background-color: $purple-100;		
		border-radius: 5px;
		animation: scroll-jump 3s ease-in-out infinite;
	}
}

@keyframes scroll-jump {
	0% {
		height: 20px;
	}

	50% {
		height: 70px;
	}

	70% {
		height: 20px;
	}

	100% {
		height: 20px;
	}
}