.teasers {
	@include media('tablet-portrait-down') {
		padding: 60px 0;
	}
	padding: 100px 0;
	background-color: $ghost-white-100;

	&.teasers--background-dark-blue {
		background-color: $dark-purple-100;
		color: $ghost-white-100;
		margin-bottom: 0;

		.teasers__title {
			color: $ghost-white-100;
		}
	}

	&.teasers--background-white {
		background-color: $white-100;
	}

	&.force--5050 {
		@include media('tablet-portrait-down') {
			.teasers_items {
				gap: 30px 20px !important;
			}
			.teaser {
				width: calc(50% - 10px) !important;
			}
		}
	}
}

a.teaser {
	transition: all 300ms ease-in-out;

	&:hover {
		opacity: 0.6;
	}
}
.teasers__title {
	@include media('tablet-portrait-down') {
		margin-bottom: 60px;
		font-size: 19px;
	}
	margin-bottom: 85px;
	text-align: center;

	em {
		font-style: normal;
		color: $blue-100;
	}
}

.teasers_items {
	@include media('tablet-portrait-down') {
		gap: 40px 50px;
	}
	display: flex;
	flex-wrap: wrap;
	gap: 80px 100px;
	justify-content: center;

	&[data-items='1'] {
		.teaser {
			width: 100%;
		}
	} 

	&[data-items='2'] {
		.teaser{
			@include media('phone') {
				width: 100%;
			}
			width: calc(50% - 65px);
		}
	} 

	&[data-items='3'] {
		.teaser {
			@include media('phone') {
				width: 100%;
			}
			width: calc(33% - 65px);
		}
	}

	&[data-items='4'] {
		.teaser {
			@include media('phone') {
				width: 100%;
			}
			width: calc(25% - 75px);
		}
	}

	&[data-items='5'], &[data-items='6'], &[data-items='7'], &[data-items='8'], &[data-items='9'], &[data-items='10'], &[data-items='11'], &[data-items='12'], &[data-items='13'], &[data-items='14'], &[data-items='15'] {
		justify-content: center;
		.teaser {
			@include media('phone') {
				width: 100%;
			}
			width: calc(25% - 75px);
		}
	}
}

.teaser {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-content: flex-start;
	max-width: 310px;
}

.teaser__editor {
	@include media('tablet-portrait-down') {
		padding: 0 15px;
	}
	font-weight: 400;
}

.teaser__icon {
	width: 100px;
	height: 100px;
	background: $white-100;
	border: 1px solid rgba(29, 1, 88, 0.04);
	border-radius: 11.2379px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 30px;

	svg {
		height: 50px;
		width: 50px;
		fill: $blue-100
	}
}

.teaser__title {
	@include media('tablet-portrait-down') {
		font-size: 18px;
	}
	width: 100%;
	font-weight: 700;
	font-size: 21.0002px;
	line-height: 120%;
	letter-spacing: -0.02em;
	text-align: center;
	font-family: map-get($font-family, 'heading');
	margin-bottom: 12px;
}