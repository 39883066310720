.has-lazy-load {
	position: relative;
	top: -150px;
	transition: all 750ms ease;
	transition-delay: 500ms;
	opacity: 0;

	&.header {
		transition-delay: 750ms;
		transition: all 500ms ease;
	}
}

.has-lazy-load[loaded='true'] {
	top: 0;
	opacity: 1;
}
