f/**
 * Button.
 */

%button,
.button {
	@include font(16px, 24px);
	display: inline-flex;
	align-items: center;
	justify-content: center;
	height: 48px;
	padding: 10px 10px;
	border-radius: 10px;
	text-decoration: none;
	transition: all 250ms ease;
	cursor: pointer;
	font-weight: 600;

	svg {
		width: 21px;
		height: 20px;
	}

	&.has-svg-right {
		svg {
			margin-left: 10px;
		}
	}

	&.has-svg-left {
		svg {
			margin-right: 10px;
		}
	}
}

/**
 * Button (default).
 */

%button--default,
.button--default {
	background-color: $black-100;
	color: $white-100;

	&:hover {
		background-color: $white-100;
		color: $black-100;
	}
}


.button--secondary {
	@include media('tablet-landscape-down') {
		padding: 12px 24px;
	}
	background-color: $white-100;
	color: $dark-purple-100;
	padding: 16px 38px;
	border-radius: 8px;
	transition: all 300ms ease-in-out;

	&:hover {
		opacity: 0.6;
	}
}

.button--primary {
	@include media('tablet-portrait-down') {
		padding: 12px 38px;
	}
	display: block;
	width: fit-content;
	background-color: $blue-100;
	color: $white-100;
	padding: 16px 38px;
	border-radius: 8px;
	transition: all 300ms ease-in-out;

	svg {
		width: 11px;
		height: 11px;
		margin-left: 10px;
	}

	&:hover {
		opacity: 0.6;
	}
}