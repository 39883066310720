.pagination {
	width: 100%;
	margin-bottom: 100px;

	.wrapper {
		justify-content: center;
	}

	.page-numbers {
		@include font(18px, 22px);
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 15px;
		transition: all 300ms ease;
		color: $purple-100;
		font-weight: 400;
		opacity: 0.4;

		&:hover {
			opacity: 1;
		}

		&.current {
			font-weight: 700;
			color: $white-100;
			opacity: 1;
			background-color: $purple-100;
			width: 36px;
			height: 36px;
			border-radius: 50px;
			line-height: 100%;
		}

		&.prev,
		&.next {
			margin-left: 30px;
			font-weight: 400;
			font-size: 16px;
			line-height: 120%;
			letter-spacing: 0.02em;
			opacity: 1;
			position: relative;
			line-height: 19px;

			&::after {
				content: '';
				width: 100%;
				height: 1px;
				background-color: $purple-100;
				position: absolute;
				left: 0;
				bottom: 5px;
			}

			&:hover {
				opacity: 0.6;
			}
		}

		&.next {
			margin-right: 30px;
			margin-left: 0;
		}
	}
}
