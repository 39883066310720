/**
 * Default fields.
 */

label {
	display: block;
	margin-bottom: 8px;
	font-weight: bold;
	color: $black-100;
	margin-left: 15px;
}

%input,
select,
input,
textarea {
	@include font(16px, 23px);
	width: 100%;
	height: 42px;
	border-radius: 4px;
	padding: 11px 15px;
	background-color: $white-100;
	font-weight: 500;
	border: 1px solid $black-100;
	color: $black-100;

	&:focus {
		background-color: $white-100;
	}
}

// textarea {
// 	height: 230px;
// 	padding-top: 15px;
// }

.gfield_required {
	color: $red-100;
}

.ginput_container_select {
	position: relative;
	width: 100%;
	overflow: hidden;
	background-color: transparent;
	border: 1px solid $black-100;

	svg {
		position: absolute;
		top: 14px;
		right: 20px;
		width: 9px;
		height: 13px;
		z-index: 1;
	}

	select {
		position: relative;
		width: calc(100% + 50px);
		max-width: none;
		margin: -2px;
		background-color: transparent;
		border: 0;
		z-index: 2;
	}
}

input[type='radio'],
input[type='checkbox'] {
	width: 15px;
	height: 15px;
	border: 2px solid $black-100;
	border-radius: 2px;
	background-color: $white-100;
	transition: all 250ms ease;
	position: relative;

	&::after {
		content: '';
		transition: all 300ms ease-in-out;
		opacity: 0;
		width: 15px;
		height: 15px;	
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);	
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3C!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --%3E%3Cpath d='M443.3 100.7C449.6 106.9 449.6 117.1 443.3 123.3L171.3 395.3C165.1 401.6 154.9 401.6 148.7 395.3L4.686 251.3C-1.562 245.1-1.562 234.9 4.686 228.7C10.93 222.4 21.06 222.4 27.31 228.7L160 361.4L420.7 100.7C426.9 94.44 437.1 94.44 443.3 100.7H443.3z' fill='%23120156'/%3E%3C/svg%3E");
	}

	&:checked {
		// background-color: $white-100;

		&::after {
			opacity: 1;
		}
	}
}

input[type='radio'] {
	border-radius: 20px;
}


/**
 * Gravity Forms.
 */

.gfield {
	margin-bottom: 25px;
}

.gform_validation_container {
	display: none;
}

.hidden_label > label {
	display: none;
}

.validation_message {
	@include font(14px, 23px);
	margin-top: 5px;
	border-radius: 8px;
	padding: 10px 10px;
	display: flex;
	align-items: center;
	width: 100%;
	color: $red-100;
	background-color: $red-10;

	> svg {
		width: 16px;
		height: 16px;
		margin-right: 5px;
		margin-top: -2px;
	}
}

%validation_error,
.validation_error {
	display: none;
	position: relative;
	margin: 0 0 50px;
	padding: 25px 25px 25px 75px;
	background: $red-10;
	color: $red-100;

	> svg {
		position: absolute;
		top: calc(50% - 15px);
		left: 30px;
		width: 31px;
		height: 30px;
		color: $red-100;
	}

	.close-button {
		position: absolute;
		top: 5px;
		right: 5px;
		cursor: pointer;

		svg {
			width: 16px;
			height: 16px;
			color: $black-100;
		}
	}
}

.gform_confirmation_message {
	width: 100%;
	padding: 50px;
	background: $red-100;

	br {
		display: none;
	}

	h4 {
		display: flex;
		align-items: center;
		margin-bottom: 20px;

		svg {
			width: 28px;
			height: 28px;
			margin-right: 20px;
			border-radius: 20px;
			background-color: $white-100;
			padding: 5px;
			color: $black-100;
		}
	}
}
