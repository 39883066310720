/**
 * Remove margin and padding from every selector including their
 * psuedo selectors.
 *
 * 1. More sensible default box sizing.
 *    (css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice).
 */

*,
*::before,
*::after {
	margin: 0;
	padding: 0;
	box-sizing: inherit; /* 1 */
}


/**
 * Set height of the document to maxium viewport height.
 *
 * 1. Set root font size to 10.
 */

html {
	height: 100vh;
	font-size: 62.5%; /* 1 */
	box-sizing: border-box;
}


/**
 * Set minimal height of the body to maximum viewport height to
 * match the document's height.
 *
 * 1. Firefox 25+.
 * 2. Chrome 5+.
 */

body {
	@include text($body-text);
	min-height: 100vh;
	background-color: $body-background-color;
	color: $dark-purple-100;
	font-family: $body-font-family;
	font-weight: 400;
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale; /* 1 */
	-webkit-font-smoothing: antialiased; /* 2 */
	overflow-y: overlay;

	&.is-active {
		overflow: hidden;
	}
}
