/**
 * Grid.
 */

// Grid width.
// Maximum container width.
$grid-width: 1024px;

// Grid columns.
// Amount of grid columns to calculate a column width with.
$grid-columns: 12;

// Grid gap.
// Space between grid columns, automated.
$grid-gap: 20px;


/**
 * Assets.
 */

// Image path.
$image-path: '../images/';


/**
 * Body.
 */

// Body background color.
$body-background-color: $ghost-white-100;

// Body color.
$body-color: $purple-100;

// Body font family.
$body-font-family: map-get($font-family, 'default');

// Body text.
$body-text: 'p';


/**
 * Other.
 */

// Link color.
$link-color: $black-100;

// Select color.
*::selection {
  background-color: $blue-100;
  color: $white-100;
}

/**
 * Responsive.
 */

// Grid breakpoint alias.
$breakpoints: (
	xs: null,
	sm: 'phone',
	md: 'tablet-portrait-up',
	lg: 'tablet-landscape-up',
	xl: 'desktop'
);
