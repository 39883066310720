/**
 * Custom user agent resets and overrides that are not present in
 * Normalize 8.0.0. This mostly removes some annoying margin and padding
 * defaults, border radius, and outlines.
 */

a {
	color: inherit;
	text-decoration: none;
}


/**
 * 1. Remove border radius on Chrome 62.0+ on macOS.
 */

button {
	border: 0;
	border-radius: 0; /* 1 */
	outline: none;
	background: none;
	cursor: pointer;
}

fieldset {
	margin: 0;
	padding: 0;
	border: 0;
}

figure {
	margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	padding: 0;
	font-size: inherit;
	font-weight: inherit;
}

hr {
	margin: 0;
	padding: 0;
}


/**
 * 1. Remove white space.
 */

img {
	max-width: 100%;
	height: auto;
	vertical-align: middle; /* 1 */
}


/**
 * 1. Remove inherited user agent appearance.
 */

input,
select,
textarea {
	max-width: 100%;
	border: 0;
	border-radius: 0;
	outline: none;
	appearance: none; /* 1 */
}


/**
 * 1. Only allow vertical resizing to prevent the textarea
 *    from overflowing the container.
 */

textarea {
	resize: vertical;
}

ul,
ol {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

p {
	margin: 0;
}


/**
 * 1. Collapse borders to prevent double sided borders.
 */

table {
	max-width: 100%;
	border-collapse: collapse; /* 1 */
}

strong {
	font-weight: 700;
}
