.team {
	margin: 100px 0;
}

.team__title {
	margin-bottom: 75px;
}

.team-members {
	justify-content: space-between;
	display: flex;
	flex-wrap: wrap;
	gap: 65px 32px;
}

.team-member {
	@include media('tablet-landscape-down') {
		width: calc(33% - 25px);
	}
	@include media('tablet-portrait-down') {
		width: calc(50% - 25px);
	}
	display: flex;
	flex-wrap: wrap;
	width: calc(25% - 25px);
}

.team-member__picture {
	width: 100%;
	margin-bottom: 30px;

	img {
		@include media('tablet-portrait-down') {
			width: 100%;
			height: auto;
			aspect-ratio: 1 / 1;
		}
		width: 302px;
		height: 300px;
		object-fit: cover;
		object-position: center;
		border-radius: 16px;
	}
}

.team-member__name {
	@include media('tablet-portrait-down') {
		font-size: 15px;
	}
	font-weight: 500;
	font-size: 18px;
	line-height: 120%;
	letter-spacing: -0.02em;
	font-family: map-get($font-family, 'heading');
	margin-bottom: 7px;
}

.team-member__function {
	@include media('tablet-portrait-down') {
		font-size: 15px;
	}
	font-weight: 500;
	font-size: 18px;
	line-height: 120%;
	letter-spacing: -0.02em;
	font-family: map-get($font-family, 'heading');
	margin-bottom: 7px;
	opacity: 0.75;
}