@keyframes fade-in {
	100% {
		opacity: 1;
	}
}
.header {
	@include media('tablet-landscape-down') {
		z-index: 1000;
		background-color: $ghost-white-100;
	}
	background: transparent;
    position: absolute;
    top: 0;
    width: 100%;
	padding-top: 25px;
	padding-bottom: 10px;
	opacity: 0;
	z-index: 1000;
	animation: fade-in 200ms ease-in-out forwards;
	animation-delay: 300ms;

	&.is-fixed {
		position: fixed;
	}

	> .wrapper--extra {
		max-width: calc(1520px + 100px);
	}

	&.has-breadcrumbs {
		border-bottom: 1px solid rgba(18, 1, 88, 0.05);
	}

	&.is-scrolling {
		@include media('tablet-portrait-down') {
			margin-top: 0;
		}
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		background-color: $white-100;
		z-index: 10000;
		opacity: 1;
		animation: jump-in 400ms ease-in-out forwards;
		padding-bottom: 20px;

		.submenu {
			top: 65px !important;
		}
	}

	
	&.is-scrolling-down {
		opacity: 1;
		animation: jump-out 400ms ease-in-out forwards;
	}
}

.navigation {
	@include media('tablet-landscape-down') {
		display: none;
	}
	display: flex;
	flex-wrap: wrap;
	margin-left: auto;
	margin-top: auto;

	.navigation__item {
		margin-right: 50px;
		// padding-bottom: 8px;
		font-weight: 500;
		font-size: 15px;
		line-height: 19px;
		letter-spacing: 0.04em;
		height: 24px;
		position: relative;
		display: flex;
		align-items: center;
		margin-bottom: 3px;

		> span svg {
			margin-left: 10px;
		}

		&.is-current {
			&::before {
				width: 100%;
			}
		}

		.navigation__item-link {
			cursor: pointer;
			z-index: 100;
		}

		svg {
			width: 24px;
			height: 24px;
			margin-right: 10px;
		}

		span {
			svg {
				margin-right: 0;
				height: 11px;
				width: 11px;
				fill: $dark-purple-100;
			}
		}

		&:last-child {
			margin-right: 0;
			font-weight: 400;
			transition: all 300ms ease-in-out;
			
			&::before {
				display: none;
			}

			&:hover {
				opacity: 0.6;
			}
		}

		> a {
			position: relative;

			&::before {
				content: '';
				position: absolute;
				height: 2px;
				border-radius: 5px;
				background-color: $blue-100;
				width: 0%;
				bottom: -3px;
				left: 0;
				transition: all 300ms ease-in-out;
			}
		}

		.submenu {
			position: absolute;
			padding: 55px 25px;
			padding-bottom: 45px;
			top: 30px;
			left: 50%;
			transform: translateX(-50%);
			opacity: 0;
			width: max-content;
			pointer-events: none;
			background-color: $white-100;
			border-radius: 8px;
			box-shadow: 0px 0px 20px rgba(29, 1, 88, 0.05);
			transition: all 300ms ease-in-out;
			z-index: 100;

			&::before {
				content: '';
				top: -16px;
				left: 50%;
				transform: translateX(-50%);
				background-image: url("data:image/svg+xml,%3Csvg width='55' height='16' viewBox='0 0 55 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_780_6572)'%3E%3Cpath d='M-81 24.5C-81 20.0817 -77.4183 16.5 -73 16.5H7.11817C9.28011 16.5 11.35 15.625 12.8564 14.0743L21.491 5.18573C24.7342 1.84713 30.1318 1.97141 33.2179 5.45574L40.6124 13.8043C42.1308 15.5186 44.311 16.5 46.6011 16.5H128C132.418 16.5 136 20.0817 136 24.5V285.5C136 289.918 132.418 293.5 128 293.5H-73C-77.4183 293.5 -81 289.918 -81 285.5V24.5Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_780_6572'%3E%3Crect width='55' height='16' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center;
				width: 55px;
				height: 16px;
				position: absolute;
				filter: drop-shadow(0px 0px 20px rgba(29, 1, 88, 0.05));
			}
		}

		.submenu__block {
			&.mobile-only {
				@include media('tablet-landscape-down') {
					display: flex;
				}
	
				display: none;
			}
		}

		.submenu__block-links {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			margin-bottom: 23px;

			span {
				display: block;
				margin-right: 10px;
			}

			svg {
				width: 15px;
				height: 15px;

				path {
					fill: $dark-purple-100;
				}
			}

			a {
				font-weight: 400;
				font-size: 15px;
				line-height: 19px;
				letter-spacing: 0.04em;
				position: relative;
				cursor: pointer;
				display: flex;
				flex-wrap: wrap;

				p {
					position: relative;
					line-height: 100% !important;
				}
				p::before {
					content: '';
					position: absolute;
					height: 2px;
					border-radius: 5px;
					background-color: $blue-100;
					width: 0%;
					bottom: -3px;
					left: 0;
					transition: all 300ms ease-in-out;
				}

				&:hover {
					p:before {
						width: 100%;
					}
				}

				&.is-active {
					&:before {
						width: 100%;
					}
				}
			}
		}

		@include media('tablet-landscape-up') {
			&:hover {
				> a:before {
					width: 100%;
				}
	
				&::after {
					content: '';
					width: 220px;
					height: 50px;
					position: absolute;
					top: 18px;
					left: 50%;
					transform: translateX(-50%);
					// z-index: -1;
				}
	
				.submenu {
					opacity: 1;
					top: 50px;
					pointer-events: all;
				}
			}
		}
	}
}

$transition-timing: 400ms;
 .header__menu-button {
	 @include media('tablet-landscape-down') {
		 display: block;
		 position: relative;
		 width: 24px;
		 height: 17px;
		 z-index: 100;
		 margin: auto;
		 margin-right: 0;
 
		 span {
			 display: inline-block;
			 position: absolute;
			 right: 0;
			 left: 0;
			 width: 24px;
			 height: 3px;
			 border-radius: 5px;
			 margin: auto;
			 background-color: $purple-100;
 
			 &:nth-child(1) {
				 top: 0;
				 transition: background-color $transition-timing, top $transition-timing $transition-timing, transform $transition-timing 0s;
			 }
 
			 &:nth-child(2) {
				 top: calc(50% - 2px);
				 transition: background-color $transition-timing, opacity 0ms $transition-timing;
				 background-color: $purple-100;
				 opacity: 1;
			 }
 
			 &:nth-child(3) {
				 bottom: 0;
				 transition: background-color $transition-timing, bottom $transition-timing $transition-timing, transform $transition-timing 0s;
				 background-color: $purple-100;
			 }
		 }
 
		 &.is-active {
			 span {
				 &:nth-child(1) {
					 margin: 0;
					 width: 24px;
					 top: calc(50% - 1px);
					 transform: rotate(-45deg);
					 transition: background-color $transition-timing, top $transition-timing, transform $transition-timing $transition-timing;
				 }
 
				 &:nth-child(2) {
					 opacity: 0;
				 }
 
				 &:nth-child(3) {
					 margin: 0;
					 width: 24px;
					 bottom: calc(50% - 2px);
					 transform: rotate(45deg);
					 transition: background-color $transition-timing, bottom $transition-timing, transform $transition-timing $transition-timing;
				 }
			 }
		 }
	 }
	 @include media('tablet-portrait-down') {
		span {
			&:nth-child(2) {
				top: calc(50% - 1px);
			}
		}
	 }
	 @include media('phone') {
		margin-right: 14px;
	 }
 
	 display: none;
 }

 @keyframes jump-in {
	0% {
		top: -101px;
	}

	100% {
		top: 0;
	}
}

@keyframes jump-out {
	0% {
		top: 0;
	}

	100% {
		top: -101px;
	}
}