.content-default {
	@include media('tablet-portrait-down') {
		padding: 60px 0;
	}
	padding: 100px 0;


	&.one-way-spacing {
		padding-bottom: 0;
	}
}


.content-default__title {
	margin-bottom: 30px;

	em {
		font-style: normal;
		color: $blue-100;
	}
}