.blog-item {
	background-color: $white-100;
	border: 1.05px solid rgba(29, 1, 88, 0.04);
	border-radius: 17px;

	&:hover {
		.blog-item__image {

			&::after {
				background-color: $purple-50;
			}

			.hover {
				opacity: 1;
				pointer-events: all;
			}

			img {
				transform: scale(1.03);
			}
		}
	}
}

.blog-item__image {
	@include media('tablet-portrait-down') {
		height: 180px;
	}
	width: 100%;
	border-radius: 17px 17px 0px 0px;
	height: 260px;
	overflow: hidden;
	position: relative;

	img {
		@include media('tablet-portrait-down') {
			height: 180px;
		}
		width: 100%;
		border-radius: 17px 17px 0px 0px;
		height: 260px;
		object-fit: cover;		
		transition: all 300ms ease-in-out;
	}

	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		background-color: transparent;
		width: 100%;
		height: 100%;
		transition: all 300ms ease-in-out;
	}

	.hover {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 1000;
		opacity: 0;
		pointer-events: none;
		transition: all 300ms ease-in-out;

		.button--secondary {
			border: 1px solid $white-100;
			background: linear-gradient(253.09deg, rgba(246, 247, 252, 0.25) -7.53%, rgba(246, 247, 252, 0.25) 97.04%);
			color: $white-100;
			transition: all 300ms ease-in-out;

			&:hover {
				background-color: $white-100;
				color: $purple-100;
				opacity: 1;
			}
		}
	}
}

.blog-item__body {
	margin-top: 43px;
	padding: 0 23px;
	margin-bottom: 63px;
}

.blog-item__subtitle {
	display: flex;
	flex-wrap: wrap;
	color: $blue-100;
	align-items: center;
	margin-bottom: 21px;

	p {
		display: flex;
		font-family: map-get($font-family, 'heading');
		font-weight: 500;
		font-size: 16px;
		line-height: 20px;
		display: flex;
		align-items: center;
		letter-spacing: -0.05em;
	}

	span {
		display: flex;
		height: 18px;
		width: 2px;
		background-color: $blue-100;
		margin: 0 10px;
	}
}

.blog-item__title {
	font-weight: 500;
	font-size: 21px;
	line-height: 26px;
}