.blog-list {
	@include media('tablet-portrait-down') {
		margin-bottom: 100px;
	}
	margin-bottom: 200px;
	margin-top: 40px;
}

.blog-list__title {
	@include media('tablet-portrait-down') {
		margin-bottom: 40px;
	}
	margin-bottom: 80px;
}

.blog-list__wrap {
	@include media('tablet-portrait-down') {
		gap: 30px;
	}
	gap: 64px 32px;

	.blog-item {
		@include media('tablet-landscape-down') {
			width: calc(50% - 18px);
		}
		@include media('tablet-portrait-down') {
			width: 100%;
		}
		width: calc(33% - 18px);
	}
}