.blog-select {
	background-color: $white-100;
	padding: 80px 0;
}

.blog-select__header {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 80px;
}

.blog-select__link {
	display: flex;
	font-weight: 500;
	font-size: 18px;
	line-height: 120%;
	letter-spacing: -0.02em;
	text-decoration-line: underline;
	transition: all 300ms ease-in-out;
	font-family: map-get($font-family, 'heading');
	color: $purple-100;

	&:hover {
		opacity: 0.6
	}
}

.blog-select__title {
	em {
		color: $blue-100;
		font-style: normal;
	}
}

.blog-select__wrap {
	justify-content: space-between;

	.blog-item {
		@include media('tablet-portrait-down') {
			width: 100%;
		}
		background-color: $ghost-white-100;
	}
}