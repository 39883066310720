.text-image {
	@include media('tablet-portrait-down') {
		padding: 60px 0;
	}
	padding: 100px 0;
	&.text-image--background-dark-blue {
		background-color: $dark-purple-100;

		color: $white-100;

		.text-image__title {
			color: $white-100;
		}
	}

	&.text-image--background-white {
		background-color: $white-100;
	}

	&.text-image--image-right {
		> .wrapper {
			flex-direction: row-reverse;
		}

		.text-image__image img{
			margin-right: 0;
			margin-left: auto;
		}
	}

	&.text-image--alignment-top {
		.text-image__text {
			align-content: flex-start;
			display: flex;
			flex-wrap: wrap;
		}
	}

	&.text-image--alignment-center {
		.text-image__text {
			align-content: center;
			display: flex;
			flex-wrap: wrap;
		}
	}

	&.text-image--alignment-bottom {
		.text-image__text {
			align-content: flex-end;
			display: flex;
			flex-wrap: wrap;
		}
	}

	.wrapper {
		@include media('tablet-portrait-down') {
			gap: 20px;
		}
	}
}

.text-image__image {
	@include media('phone') {
		width: 100%;
	}

	display: flex;
	width: calc(50% - 75px);
	margin-right: auto;

	img {
		width: auto;
		margin-right: auto;
		max-height: 480px;
		object-fit: cover;
		aspect-ratio: 1 / 1;
		&.text-image__image--border-top-right {
			border-radius: 16px 168px 16px 16px;
		}

		&.text-image__image--border-top-left {
			border-radius: 168px 16px 16px 16px;
		}

		&.text-image__image--border-bottom-right {
			border-radius: 16px 16px 168px 16px;
		}

		&.text-image__image--border-bottom-left {
			border-radius: 16px 16px 16px 168px;
		}
	}
}

.text-image__text {
	@include media('phone') {
		width: 100%;
		margin-bottom: 0px;
	}
	width: 50%;
}

.text-image__title {
	margin-bottom: 30px;
	
	em {
		font-style: normal;
		color: $blue-100;
	}
}

.text-image__editor  {
	margin-bottom: 30px;
}

.text-image__stats {
	@include media('tablet-portrait-down') {
		margin-bottom: 30px;
	}
	margin-bottom: 45px;
}

.text-image__link {
	display: block;
	width: fit-content;
}

.text-image__stats {
	@include media('tablet-portrait-down') {
		justify-content: space-between;
	}
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
}

.text-image__stat {
	display: grid;
	flex-wrap: wrap;
	width: fit-content;

	.label {
		@include media('tablet-portrait-down') {
			font-size: 36px;
			line-height: 39px;
			margin-bottom: 10px;
		}
		font-family: map-get($font-family, 'heading');
		width: 100%;
		font-weight: 700;
		font-size: 48px;
		line-height: 110%;
		letter-spacing: -0.02em;
		margin-bottom: 5px;
	}

	.sublabel {
		@include media('tablet-portrait-down') {
			font-size: 10px;
			line-height: 12px;
		}
		width: 100%;
		font-weight: 400;
		font-size: 14px;
		line-height: 120%;
		letter-spacing: 0.03em;
		opacity: 0.7;
	}
}