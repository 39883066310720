.blog-single {
	@include media('tablet-portrait-down') {
		margin-top: 40px;
	}
	margin-top: 55px;
	margin-bottom: 100px;
}

.blog-single__subtitle {
	display: flex;
	flex-wrap: wrap;
	color: $blue-100;
	align-items: center;
	margin-bottom: 21px;

	p {
		display: flex;
		font-family: map-get($font-family, 'heading');
		font-weight: 500;
		font-size: 16px;
		line-height: 20px;
		display: flex;
		align-items: center;
		letter-spacing: -0.05em;
	}

	span {
		display: flex;
		height: 18px;
		width: 2px;
		background-color: $blue-100;
		margin: 0 10px;
	}
}

.blog-single__title {
	@include media('tablet-portrait-down') {
		font-size: 28px;
	}
	font-size: 52px;
	line-height: 140%;
	margin-bottom: 40px;
}

.blog-single__image {
	@include media('tablet-portrait-down') {
		height: 300px;
	}
	border-radius: 16px;
	height: 530px;
	margin-bottom: 40px;
	
	img {
		@include media('tablet-portrait-down') {
			height: 300px;
		}
		border-radius: 16px;
		object-fit: cover;
		width: 100%;
		height: 530px;
	}
}

.blog-single__body {
	@include media('tablet-landscape-down') {
		padding: 0 60px;
	}
	@include media('tablet-portrait-down') {
		padding: 0;
	}
	max-width: 864px;
	margin: 0 auto;
}

.blog-single__back {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-top: 100px;
	transition: all 300ms ease-in-out;

	span {
		font-weight: 500;
		font-size: 18px;
		line-height: 120%;
		letter-spacing: -0.02em;
		text-decoration-line: underline;
	}

	span.svg {
		margin-right: 24px;
		width: 50px;
		height: 50px;
		border-radius: 100px;
		background-color: $purple-100;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		filter: drop-shadow(0px 8px 10px rgba(0, 0, 0, 0.1));

		svg {
			fill: $white-100;
			width: 22px;
			height: 22px;
		}
	}

	&:hover {
		opacity: 0.6;
	}
}