/**
 * Visually hide any element from the user.
 */

.visually-hidden {
	display: block;
	position: absolute;
	width: 1px;
	height: 1px;
	white-space: nowrap;
	overflow: hidden;
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(1px);
}


/**
 * Make lazyload placeholder 100% wide.
 */

.lazyload {
	width: 100%;
}


/**
 * Helper class for object fit polyfill. Add this class to any element
 * that utilizes the `object-fit` property.
 */

%object-fit,
.object-fit {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	font-family: 'object-fit: cover;';
	object-fit: cover;
}


/**
 * Align center.
 */

.align-center {
	text-align: center;
}

/**
 * Align left.
 */

.align-left {
	text-align: left;
}

/**
 * Align right.
 */

.align-right {
	text-align: right;
}
