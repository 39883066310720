
/**
 * Font family utility classes.
 */

@each $key, $breakpoint in $breakpoints {
	@include media($breakpoint) {
		@each $property, $value in $font-family {
			.text-#{$key}-#{$property} {
				font-family: map-get($font-family, $property);
			}
		}
	}
}


/**
 * Text size utility classes.
 */

@each $key, $breakpoint in $breakpoints {
	@include media($breakpoint) {
		@each $property, $value in $font-size {
			.text-#{$key}-#{$property} {
				@include text($property);
			}
		}
	}
}


/**
 * Text alignment utility classes.
 */

@each $key, $breakpoint in $breakpoints {
	@include media($breakpoint) {
		@each $direction in left, right, center {
			.text-#{$key}-#{$direction} {
				text-align: $direction;
			}
		}
	}
}


/**
 * Font weight utility classes.
 *
 * 1. Multiply looped integer by 100 for correct font weight value.
 */

@each $key, $breakpoint in $breakpoints {
	@include media($breakpoint) {
		@for $i from 1 through 9 {
			$i: $i * 100; // [1]

			.text-#{$key}-#{$i} {
				font-weight: #{$i};
			}
		}
	}
}


/**
 * Text transform uitlity classes.
 */

@each $key, $breakpoint in $breakpoints {
	@include media($breakpoint) {
		@each $transform in capitalize, uppercase, lowercase, none, full-width {
			.text-#{$key}-#{$transform} {
				text-transform: $transform;
			}
		}
	}
}
