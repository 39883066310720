/**
* Self hosted google webfonts.
* https://google-webfonts-helper.herokuapp.com/
**/
/* space-grotesk-300 - latin */
@font-face {
	font-family: 'Space Grotesk';
	font-style: normal;
	font-weight: 300;
	src: url('../fonts/space-grotesk-v13-latin-300.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('../fonts/space-grotesk-v13-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('../fonts/space-grotesk-v13-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../fonts/space-grotesk-v13-latin-300.woff') format('woff'), /* Modern Browsers */
		 url('../fonts/space-grotesk-v13-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('../fonts/space-grotesk-v13-latin-300.svg#SpaceGrotesk') format('svg'); /* Legacy iOS */
  }
  /* space-grotesk-regular - latin */
  @font-face {
	font-family: 'Space Grotesk';
	font-style: normal;
	font-weight: 400;
	src: url('../fonts/space-grotesk-v13-latin-regular.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('../fonts/space-grotesk-v13-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('../fonts/space-grotesk-v13-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../fonts/space-grotesk-v13-latin-regular.woff') format('woff'), /* Modern Browsers */
		 url('../fonts/space-grotesk-v13-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('../fonts/space-grotesk-v13-latin-regular.svg#SpaceGrotesk') format('svg'); /* Legacy iOS */
  }
  /* space-grotesk-500 - latin */
  @font-face {
	font-family: 'Space Grotesk';
	font-style: normal;
	font-weight: 500;
	src: url('../fonts/space-grotesk-v13-latin-500.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('../fonts/space-grotesk-v13-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('../fonts/space-grotesk-v13-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../fonts/space-grotesk-v13-latin-500.woff') format('woff'), /* Modern Browsers */
		 url('../fonts/space-grotesk-v13-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('../fonts/space-grotesk-v13-latin-500.svg#SpaceGrotesk') format('svg'); /* Legacy iOS */
  }
  /* space-grotesk-600 - latin */
  @font-face {
	font-family: 'Space Grotesk';
	font-style: normal;
	font-weight: 600;
	src: url('../fonts/space-grotesk-v13-latin-600.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('../fonts/space-grotesk-v13-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('../fonts/space-grotesk-v13-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../fonts/space-grotesk-v13-latin-600.woff') format('woff'), /* Modern Browsers */
		 url('../fonts/space-grotesk-v13-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('../fonts/space-grotesk-v13-latin-600.svg#SpaceGrotesk') format('svg'); /* Legacy iOS */
  }
  /* space-grotesk-700 - latin */
  @font-face {
	font-family: 'Space Grotesk';
	font-style: normal;
	font-weight: 700;
	src: url('../fonts/space-grotesk-v13-latin-700.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('../fonts/space-grotesk-v13-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('../fonts/space-grotesk-v13-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../fonts/space-grotesk-v13-latin-700.woff') format('woff'), /* Modern Browsers */
		 url('../fonts/space-grotesk-v13-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('../fonts/space-grotesk-v13-latin-700.svg#SpaceGrotesk') format('svg'); /* Legacy iOS */
  }

  /* lexend-100 - latin */
@font-face {
	font-family: 'Lexend';
	font-style: normal;
	font-weight: 100;
	src: url('../fonts/lexend-v17-latin-100.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('../fonts/lexend-v17-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('../fonts/lexend-v17-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../fonts/lexend-v17-latin-100.woff') format('woff'), /* Modern Browsers */
		 url('../fonts/lexend-v17-latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('../fonts/lexend-v17-latin-100.svg#Lexend') format('svg'); /* Legacy iOS */
  }
  /* lexend-200 - latin */
  @font-face {
	font-family: 'Lexend';
	font-style: normal;
	font-weight: 200;
	src: url('../fonts/lexend-v17-latin-200.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('../fonts/lexend-v17-latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('../fonts/lexend-v17-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../fonts/lexend-v17-latin-200.woff') format('woff'), /* Modern Browsers */
		 url('../fonts/lexend-v17-latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('../fonts/lexend-v17-latin-200.svg#Lexend') format('svg'); /* Legacy iOS */
  }
  /* lexend-300 - latin */
  @font-face {
	font-family: 'Lexend';
	font-style: normal;
	font-weight: 300;
	src: url('../fonts/lexend-v17-latin-300.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('../fonts/lexend-v17-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('../fonts/lexend-v17-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../fonts/lexend-v17-latin-300.woff') format('woff'), /* Modern Browsers */
		 url('../fonts/lexend-v17-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('../fonts/lexend-v17-latin-300.svg#Lexend') format('svg'); /* Legacy iOS */
  }
  /* lexend-regular - latin */
  @font-face {
	font-family: 'Lexend';
	font-style: normal;
	font-weight: 400;
	src: url('../fonts/lexend-v17-latin-regular.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('../fonts/lexend-v17-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('../fonts/lexend-v17-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../fonts/lexend-v17-latin-regular.woff') format('woff'), /* Modern Browsers */
		 url('../fonts/lexend-v17-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('../fonts/lexend-v17-latin-regular.svg#Lexend') format('svg'); /* Legacy iOS */
  }
  /* lexend-500 - latin */
  @font-face {
	font-family: 'Lexend';
	font-style: normal;
	font-weight: 500;
	src: url('../fonts/lexend-v17-latin-500.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('../fonts/lexend-v17-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('../fonts/lexend-v17-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../fonts/lexend-v17-latin-500.woff') format('woff'), /* Modern Browsers */
		 url('../fonts/lexend-v17-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('../fonts/lexend-v17-latin-500.svg#Lexend') format('svg'); /* Legacy iOS */
  }
  /* lexend-600 - latin */
  @font-face {
	font-family: 'Lexend';
	font-style: normal;
	font-weight: 600;
	src: url('../fonts/lexend-v17-latin-600.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('../fonts/lexend-v17-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('../fonts/lexend-v17-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../fonts/lexend-v17-latin-600.woff') format('woff'), /* Modern Browsers */
		 url('../fonts/lexend-v17-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('../fonts/lexend-v17-latin-600.svg#Lexend') format('svg'); /* Legacy iOS */
  }
  /* lexend-700 - latin */
  @font-face {
	font-family: 'Lexend';
	font-style: normal;
	font-weight: 700;
	src: url('../fonts/lexend-v17-latin-700.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('../fonts/lexend-v17-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('../fonts/lexend-v17-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../fonts/lexend-v17-latin-700.woff') format('woff'), /* Modern Browsers */
		 url('../fonts/lexend-v17-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('../fonts/lexend-v17-latin-700.svg#Lexend') format('svg'); /* Legacy iOS */
  }
  /* lexend-800 - latin */
  @font-face {
	font-family: 'Lexend';
	font-style: normal;
	font-weight: 800;
	src: url('../fonts/lexend-v17-latin-800.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('../fonts/lexend-v17-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('../fonts/lexend-v17-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../fonts/lexend-v17-latin-800.woff') format('woff'), /* Modern Browsers */
		 url('../fonts/lexend-v17-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('../fonts/lexend-v17-latin-800.svg#Lexend') format('svg'); /* Legacy iOS */
  }
  /* lexend-900 - latin */
  @font-face {
	font-family: 'Lexend';
	font-style: normal;
	font-weight: 900;
	src: url('../fonts/lexend-v17-latin-900.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('../fonts/lexend-v17-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('../fonts/lexend-v17-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../fonts/lexend-v17-latin-900.woff') format('woff'), /* Modern Browsers */
		 url('../fonts/lexend-v17-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('../fonts/lexend-v17-latin-900.svg#Lexend') format('svg'); /* Legacy iOS */
  }


  /* open-sans-regular - latin */
@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 400;
	src: url('../fonts/open-sans-v34-latin-regular.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('../fonts/open-sans-v34-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('../fonts/open-sans-v34-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../fonts/open-sans-v34-latin-regular.woff') format('woff'), /* Modern Browsers */
		 url('../fonts/open-sans-v34-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('../fonts/open-sans-v34-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
  }
  /* open-sans-700 - latin */
  @font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 700;
	src: url('../fonts/open-sans-v34-latin-700.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('../fonts/open-sans-v34-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('../fonts/open-sans-v34-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../fonts/open-sans-v34-latin-700.woff') format('woff'), /* Modern Browsers */
		 url('../fonts/open-sans-v34-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('../fonts/open-sans-v34-latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
  }
  /* open-sans-700italic - latin */
  @font-face {
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 700;
	src: url('../fonts/open-sans-v34-latin-700italic.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('../fonts/open-sans-v34-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('../fonts/open-sans-v34-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../fonts/open-sans-v34-latin-700italic.woff') format('woff'), /* Modern Browsers */
		 url('../fonts/open-sans-v34-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('../fonts/open-sans-v34-latin-700italic.svg#OpenSans') format('svg'); /* Legacy iOS */
  }