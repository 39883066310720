/**
 * Wrapper (default)
 */

.wrapper {
	@include media('tablet-portrait-down') {
		@include container(1420px, 40px);
	}
	@include media('phone') {
		@include container(1420px, 16px);
	}
	@include container(1420px, 100px);
}

.wrapper--big {
	@include media('tablet-portrait-down') {
		@include container(1470px, 40px);
	}
	@include media('phone') {
		@include container(1470px, 16px);
	}
	@include container(1470px, 40px);
}

.wrapper--extra {
	@include media('tablet-portrait-down') {
		@include container(1420px, 40px);
	}
	@include media('phone') {
		@include container(1420px, 16px);
	}
	@include container(1420px, 40px);
}


/**
 * Wrapper (small)
 */

.wrapper--small {
	@include media('tablet-portrait-down') {
		@include container(1088px, 40px);
	}
	@include media('phone') {
		@include container(1088px, 16px);
	}
	@include container(1088px, 40px);
}
