.info-blocks {
	margin: 100px 0;
	position: relative;

	.graphic {
		@include media('tablet-portrait-down') {
			width: 300%;
			height: 400px;
			right: -40px;

			img {
				position: absolute;
				bottom: 0;
				height: 100%;
				width: 100%;
				object-fit: cover;
			}
		}
		position: absolute;
		bottom: -125px;
		right: 0;
		height: 215px;
		width: 120%;
		right: -20%;
		z-index: -1;

		img {
			width: 120%;
		}
	}
}

.info-block__title {
	@include media('tablet-portrait-down') {
		margin-bottom: 60px;
	}
	margin-bottom: 80px;

	em {
		font-style: normal;
		color: $blue-100;
	}
}

.info-blocks__blocks {
	@include media('tablet-portrait-down') {
		gap: 20px;
	}
	@include media('phone') {
		gap: 40px;
	}
	display: flex;
	flex-wrap: wrap;
	gap: 32px;

	&[data-blocks='1'] {
		.info-blocks__block {
			width: 100%;
		}
	} 

	&[data-blocks='2'] {
		.info-blocks__block {
			@include media('phone') {
				width: 100%;
			}
			width: calc(50% - 20px);
		}
	} 

	&[data-blocks='3'] {
		.info-blocks__block {
			@include media('phone') {
				width: 100%;
			}
			width: calc(33% - 20px);
		}
	}

	&[data-blocks='4'] {
		.info-blocks__block {
			@include media('phone') {
				width: 100%;
			}
			width: calc(25% - 20px);
		}
	}
}

.info-blocks__block {
	@include media('phone') {
		min-height: auto;
		padding: 30px 20px;
	}
	min-height: 360px;
	padding: 40px;
	display: flex;
	flex-wrap: wrap;
	padding-bottom: 45px;
	border: 1px solid rgba(29, 1, 88, 0.04);
	border-radius: 16px;
	align-content: flex-start;
	background-color: $white-100;
	flex-direction: column;
	flex: 1 1 0 auto;
	border: 1px solid transparent;
	transition: all 300ms ease-in-out;

	svg {
		transition: all 300ms ease-in-out;
	}

	&.is-active {
		border-color: $blue-100;
		box-shadow: 0px 0px 16px rgba(23, 1, 88, 0.25);
		color: $blue-100;

		svg {
			fill: $blue-100;
		}
		
		.info-blocks__block-title {
			color: $blue-100;
		}
	}
}

.info-blocks__block-title {
	@include media('tablet-portrait-down') {
		margin-bottom: 20px;
		height: 30px;
	}
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-bottom: 38px;
	height: 40px;
	transition: all 300ms ease-in-out;

	span.icon {
		margin-right: 21px;
	}
	svg {
		@include media('tablet-portrait-down') {
			width: 26px;
			height: 26px;
		}
		width: 32px;
		height: 32px;
		fill: $purple-100;
	}

	span.text {
		@include media('tablet-portrait-down') {
			font-size: 22px;
		}
		width: calc(100% - 55px);
		font-weight: 700;
		font-size: 28px;
		line-height: 120%;
		letter-spacing: -0.02em;
		color: $purple-100;
	}
}

.info-block__editor {
	height: fit-content;
	flex-grow: 1;
	margin-bottom: 30px
}

.info-block__link {
	@extend .button--secondary;
	margin-top: auto;

	color: $blue-100;
	border-color: $blue-100;
	border: 1px solid $blue-100;
	width: fit-content;

	&:hover {
		opacity: 1;
		color: $white-100;
		background-color: $blue-100;
	}
}